import { last } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  Entity,
  FileMeta,
  hasBody,
  HasRefs,
  HasResources,
  Ref,
  RelationRef,
  RichText,
  toTitleOrName,
} from "@api";

import {
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useLazyPropertyDef } from "@state/databases";
import { useAttachFile } from "@state/resources";

import { Maybe, safeAs, when } from "@utils/maybe";
import { composel, Fn } from "@utils/fn";
import { asMutation } from "@utils/property-mutations";
import { justOne } from "@utils/array";
import { useGoTo } from "@utils/navigation";
import { fromScope } from "@utils/scope";
import { toRef } from "@utils/property-refs";

import { Props as LabelProps } from "@ui/label";
import { CollapsibleSection } from "@ui/collapsible-section";
import { append, isEmpty, isEqual, toHtml } from "@utils/rich-text";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { MagicPurple } from "@ui/icon";
import { Button } from "@ui/button";
import { ParentCard } from "@ui/parent-card";
import { AIAutoBrief } from "./ai-auto-brief";
import { HStack, VStack } from "@ui/flex";
import { UploadFileButton } from "./upload-modal";
import { Text } from "@ui/text";
import { CreatePageButton } from "@ui/engine/page";
import { useCurrentPage } from "@ui/app-page";
import { RealTimeDocumentEditor } from "./real-time-field";

interface Props {
  entity: RelationRef;
  body: Maybe<RichText>;
  placeholder?: string;
  label?: string | ReactNode;
  labelSize?: LabelProps["size"];
  showAll?: boolean;
  onBodyChanged?: Fn<RichText, void>;
}

export const GenericPageBody = ({
  body,
  label: sectionLabel,
  onBodyChanged,
  entity: ref,
  placeholder,
  showAll: _showAll = true,
  labelSize = "medium",
}: Props) => {
  const pageId = useCurrentPage();
  const goTo = useGoTo();
  const mutate = useUpdateEntity(ref.id);
  const entity = useLazyEntity(ref.id);
  const nested = useNestedSource(entity);
  const onFileUploaded = useAttachFile(entity as Maybe<HasResources>, pageId);

  const [showAutoBrief, setShowAutoBrief] = useState(false);
  const [showAll, setShowAll] = useState(_showAll);
  const def = useLazyPropertyDef(entity?.source, {
    field: "body",
    type: "rich_text",
  });
  const parentRef = useMemo(
    () =>
      when(safeAs<HasRefs>(entity)?.refs?.parent, justOne) ||
      when(entity?.source.scope, composel(fromScope, last, toRef)),
    [entity]
  );
  const parent = useLazyEntity(parentRef?.id);
  const childScope = useNestedSource(entity);

  const handleChanged = useCallback(
    (rt: RichText) => {
      // Hasn't changed
      if (isEqual(rt, body)) {
        return;
      }

      if (onBodyChanged) {
        onBodyChanged(rt);
      } else {
        mutate(asMutation({ field: "body", type: "rich_text" }, rt));
      }
    },
    [mutate, onBodyChanged]
  );

  const handleFileUploaded = useCallback(
    (file: FileMeta) => {
      const markup = file.mimeType?.startsWith("image/")
        ? `<embed data-embed-type="image" data-embed-title="${file.name}" data-embed-url="${file.url}">`
        : `<p><embed data-embed-type="link" data-embed-title="${file.name}" data-embed-url="${file.url}" /></p>`;

      handleChanged(append(body, { html: markup }));
      onFileUploaded(file);
    },
    [onFileUploaded]
  );

  const handleNewPage = useCallback(
    (ref: Ref) => {
      const markup = `<a data-mention-id=${ref.id}>${
        when(safeAs<Entity>(ref), toTitleOrName) || ""
      }</a>`;
      handleChanged({ html: when(body, toHtml) || "" + markup });
    },
    [goTo]
  );

  return (
    <>
      <CollapsibleSection
        title={sectionLabel || "Documentation"}
        labelSize={labelSize}
        actions={
          <>
            <Button
              size="small"
              icon={MagicPurple}
              onClick={() => setShowAutoBrief(true)}
            >
              Auto Brief
            </Button>
          </>
        }
      >
        <VStack gap={0} fit="container">
          <ShowMoreOverflow showAll={showAll}>
            <RealTimeDocumentEditor
              key={ref.id}
              entity={ref.id}
              field="body"
              scope={childScope?.scope}
              newLineSpace="large"
              placeholder={placeholder}
              content={body}
              onChanged={handleChanged}
            />
            {showAutoBrief && def && nested && (
              <AIAutoBrief
                original={body}
                prop={def}
                source={nested}
                onDismiss={() => setShowAutoBrief(false)}
                onSaved={(rt) => {
                  handleChanged(rt);
                  setShowAutoBrief(false);
                  setShowAll(true);
                }}
              />
            )}
          </ShowMoreOverflow>
          {childScope && (
            <HStack justify="flex-end" fit="container" gap={0}>
              <UploadFileButton
                scope={childScope?.scope}
                onUploaded={handleFileUploaded}
              >
                <Text subtle>Upload</Text>
              </UploadFileButton>

              <CreatePageButton
                scope={childScope.scope}
                onCreated={handleNewPage}
              >
                <Text subtle>New Page</Text>
              </CreatePageButton>
            </HStack>
          )}
        </VStack>
      </CollapsibleSection>
      {parent && hasBody(parent) && !isEmpty(parent.body) && (
        <CollapsibleSection
          title={"Parent" + (sectionLabel || "Documentation")}
          labelSize={labelSize}
        >
          <ParentCard
            id={parent.id}
            label={false}
            onClick={() => goTo(parent.id)}
            showProps={false}
          />
        </CollapsibleSection>
      )}
    </>
  );
};
