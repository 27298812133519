import { useLayoutEffect, useRef, useState } from "react";

import { cx } from "@utils/class-names";

import { ShowMoreMenuItem } from "@ui/menu-item";
import { ArrowDown } from "@ui/icon";

import styles from "./show-more-overflow.module.css";

interface Props {
  showAll: boolean;
  children: React.ReactNode;
  label?: string;
  size?: "small" | "medium" | "large";
  onShowAll?: () => void;
  className?: string;
}

export const ShowMoreOverflow = ({
  showAll: _showAll = true,
  label = "Show more",
  size = "medium",
  className,
  onShowAll,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showAll, setShowAll] = useState(_showAll);
  const [hasMore, setHasMore] = useState(true);

  const checkOverflow = () => {
    if (!ref.current) return;

    const { scrollHeight, clientHeight } = ref.current;
    setHasMore(scrollHeight > clientHeight);
  };

  useLayoutEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    setTimeout(checkOverflow, 200); // Documents take a bit to render
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  return (
    <div
      className={cx(styles.container, className)}
      onFocus={() => setShowAll(true)}
    >
      <div ref={ref} className={cx(!showAll && styles.clipped, styles[size])}>
        {children}
      </div>
      {!showAll && hasMore && (
        <ShowMoreMenuItem
          text={label}
          icon={ArrowDown}
          className={styles.fixedBottom}
          onClick={() => (onShowAll ?? setShowAll)(true)}
        />
      )}
    </div>
  );
};
