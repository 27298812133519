import { filter, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  PropertyDef,
  PropertyRef,
  PropertyType,
} from "@api/types";

import { useLazyProperties } from "@state/databases";
import { useInstalledEntitiesForSource } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { Fn, isFunc, Pred, use } from "@utils/fn";
import { Maybe, safeAs, when } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";
import {
  isPropertyDef,
  isVisibleProp,
  sortByUseful,
  toFieldName,
} from "@utils/property-refs";
import { pick_ } from "@utils/object";
import { cx } from "@utils/class-names";
import { isPersonId } from "@utils/id";

import { Select, SelectProps, TextSubtextOption } from "@ui/select";
import { PlaceholderText } from "@ui/text";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { AngleDownIcon, Box, CornerLeftUpAlt, NoneIcon } from "@ui/icon";
import { PropertyDefLabel } from "@ui/property-def-label";
import { Button } from "@ui/button";
import { Label } from "@ui/label";

import { ManageFieldsFooter } from "./footers";

import styles from "./property.module.css";

type PropertyDefOrRef =
  | PropertyDef<Entity, PropertyType>
  | PropertyRef<Entity, PropertyType>;

type PropSelectOption = {
  id: string;
  name: string;
  ref?: PropertyDefOrRef;
};

export type Props = {
  subtitle?: string;
  options?: PropertyDefOrRef[];
  value?: PropertyDefOrRef;
  portal?: boolean;
  emptyOption?: boolean;
  icon?: ComponentOrNode;
  onChanged?: Fn<Maybe<PropertyRef>, void>;
  className?: string;
} & Omit<
  SelectProps<PropSelectOption>,
  "value" | "options" | "className" | "onChange"
>;

const toOption = (p: PropertyDefOrRef) => ({
  id: p.field,
  name: safeAs<PropertyDef>(p)?.label || toFieldName(p) || p.field,
  subtext: when(safeAs<PropertyDef>(p)?.scope, isPersonId)
    ? "(Private)"
    : undefined,
  icon: when(p, (r) => <PropertyTypeIcon {...r} />) ?? <NoneIcon />,
  ref: p,
});

export function PropertySelect({
  options,
  placeholder,
  subtitle,
  value,
  icon,
  caret,
  emptyOption,
  className,
  onChanged,
  children,
  ...rest
}: Props) {
  const selected = when(value, toOption);

  const menuOptions: PropSelectOption[] = useMemo(() => {
    const items = map(options, toOption);
    // Add empty option to start of list when not turned off
    if (emptyOption ?? true) {
      return [{ id: "{none}", name: "None", ref: undefined }, ...items];
    } else {
      return items;
    }
  }, [options]);

  return (
    <Select
      {...rest}
      placeholder={placeholder}
      options={menuOptions}
      clearable
      value={selected}
      className={{ popover: styles.select }}
      closeOnBlur={false}
      closeOnSelect={true}
      overrides={{ Option: TextSubtextOption }}
      onChange={(item) => {
        onChanged?.(when(item?.ref, pick_("type", "field")));
      }}
    >
      {children ?? (
        <div className={cx(styles.buttonContainer, className)}>
          <Button
            subtle
            size="small"
            className={styles.button}
            iconRight={!subtitle || !selected ? AngleDownIcon : undefined}
          >
            {selected && isPropertyDef(selected.ref) ? (
              <PropertyDefLabel
                prop={selected.ref}
                icon={icon}
                subtitle={subtitle}
              />
            ) : selected ? (
              <Label icon={<PropertyTypeIcon {...selected.ref} />}>
                {selected.name}
              </Label>
            ) : (
              <PlaceholderText>{placeholder}</PlaceholderText>
            )}
          </Button>
        </div>
      )}
    </Select>
  );
}

const isPickableProp = (p: PropertyDef) =>
  isVisibleProp(p) || p.field === "location";

export const ScopedPropertySelect = ({
  value,
  type: _type,
  scope,
  blacklist,
  emptyOption,
  whitelist = isPickableProp,
  onChanged,
  placeholder,
  children,
  className,
  allowOtherTypes = !_type,
  icon,
  subtitle,
  ...rest
}: Omit<Props, "options" | "value"> & {
  value?: PropertyRef;
  type: Maybe<EntityType>;
  scope: string;
  allowOtherTypes?: boolean;
  blacklist?: Pred<PropertyDef> | string[];
  whitelist?: Pred<PropertyDef> | string[];
}) => {
  const [open, _setOpen] = useState(false);
  const [type, setType] = useState<Maybe<EntityType>>(_type);
  const allTypes = useInstalledEntitiesForSource(scope);
  const typeProps = useLazyProperties(
    type && scope ? { type, scope } : undefined
  );
  const toLabel = useEntityLabels(scope);
  const available = useMemo(
    () =>
      !typeProps
        ? undefined
        : use(
            filter(
              typeProps,
              (p: PropertyDef<Entity>) =>
                (isFunc(whitelist)
                  ? whitelist(p)
                  : whitelist?.includes(p.field)) &&
                (isFunc(blacklist)
                  ? !blacklist(p)
                  : !blacklist?.includes(p.field))
            ),
            sortByUseful
          ),
    [typeProps]
  );
  const setOpen = useCallback(
    (o: boolean) => {
      _setOpen(o);
      setType(_type);
    },
    [_type]
  );

  const selected = when(value, toOption);

  const menuOptions = useMemo(() => {
    if (type) {
      const items = map(available, toOption);

      if (allowOtherTypes) {
        return [
          {
            options: [
              {
                id: "",
                name: "Packages",
                subtle: true,
                icon: CornerLeftUpAlt,
                ref: undefined,
              },
            ],
          },
          { label: `${toLabel(type)} Fields`, options: items },
        ];
      }

      return items;
    }

    return map(allTypes, (t) => ({
      id: t,
      name: toLabel(t),
      icon: Box,
      ref: undefined,
    }));
  }, [available, type, allTypes]);

  const handleChange = useCallback(
    (item: Maybe<PropSelectOption>) => {
      if (type && item?.id) {
        onChanged?.(when(item?.ref, pick_("type", "field")));
        setOpen(false);
      } else if (!!item?.id) {
        setType(item?.id as EntityType);
      } else {
        setType(undefined);
      }
    },
    [onChanged, type]
  );

  return (
    <Select
      {...rest}
      placeholder={placeholder}
      options={menuOptions}
      clearable
      value={selected}
      open={open}
      setOpen={setOpen}
      closeOnSelect={false}
      className={{ popover: styles.select }}
      overrides={{ Option: TextSubtextOption }}
      onChange={handleChange}
      footer={type && <ManageFieldsFooter type={type} scope={scope} />}
    >
      {children ?? (
        <div className={cx(styles.buttonContainer, className)}>
          <Button
            subtle
            size="small"
            className={styles.button}
            iconRight={!subtitle || !selected ? AngleDownIcon : undefined}
          >
            {selected && isPropertyDef(selected.ref) ? (
              <PropertyDefLabel
                prop={selected.ref}
                icon={icon}
                subtitle={subtitle}
              />
            ) : selected ? (
              <Label icon={<PropertyTypeIcon {...selected.ref} />}>
                {selected.name}
              </Label>
            ) : (
              <PlaceholderText>{placeholder}</PlaceholderText>
            )}
          </Button>
        </div>
      )}
    </Select>
  );
};

export default PropertySelect;
