import { filter } from "lodash";
import { useCallback, useMemo } from "react";
import { RecoilState, useRecoilState } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

import { Entity, EntityType, ID, Update } from "@api";

import {
  clearTempUpdates,
  mergeUpdates,
  persistedID,
  queueUpdate,
  removeItem,
  setItem,
  stableID,
  StoreState,
} from "@state/store";

import { composel } from "@utils/fn";
import {
  isLocalID,
  isTemplateId,
  maybeTypeFromId,
  typeFromId,
} from "@utils/id";
import { when } from "@utils/maybe";
import { now } from "@utils/now";

import { getEntityLoader } from "../queries";

import { getStore } from "../atoms";
import { useQueueUpdates, useStore } from "./core";

export const useLocalChanges = <T extends Entity>(
  id: ID,
  atom: RecoilState<StoreState<T>>
) => {
  const [store, setStore] = useRecoilState(atom);
  const changes = useMemo(
    () =>
      filter(
        store.unsaved,
        (m) =>
          m.mode === "temp" &&
          [
            id,
            stableID(id, store.aliases),
            persistedID(id, store.aliases),
          ]?.includes(m.id)
      ),
    [id, store.unsaved]
  );

  const save = useCallback(
    (additional: Update<T>[] = []) => {
      const merged = mergeUpdates([...changes, ...additional]);
      merged && setStore(queueUpdate({ ...merged, mode: undefined }));
      setStore(clearTempUpdates(id));
      return merged;
    },
    [id, changes]
  );

  const rollback = useCallback(async () => {
    setStore(composel(removeItem<T>(id), clearTempUpdates<T>(id)));

    // Reload latest from api immediately if not a local entity
    if (!isLocalID(id) && !isTemplateId(id)) {
      await getEntityLoader(id, now(), (e) => setStore(setItem<T>(e as T)));
    }
  }, [id]);

  return useMemo(
    () => ({ changes, save, rollback }),
    [id, changes, save, rollback]
  );
};

export const useRollbackTempChanges = () => {
  return useCallback((id: ID) => {
    const Store = when(typeFromId<EntityType>(id), (type) => getStore(type));

    if (!Store) {
      return;
    }

    setRecoil(Store, composel(removeItem(id), clearTempUpdates(id)));
  }, []);
};

export const useSaveTempChanges = (
  pageId?: string,
  skipWorkflows?: boolean
) => {
  const save = useQueueUpdates(pageId, skipWorkflows);
  return useCallback((id: ID) => {
    const Store = when(typeFromId<EntityType>(id), (type) => getStore(type));

    if (!Store) {
      return;
    }

    const store = getRecoil(Store);
    const changes = filter(store.unsaved, (m) => m.id === id);
    const merged = mergeUpdates<Entity>(changes);

    if (!merged) {
      return;
    }

    // Save as a single update
    save({ ...merged, mode: undefined });
    // Clear the store of temp changes
    setRecoil(Store, clearTempUpdates(id));
  }, []);
};

export function usePersistedId(id: ID) {
  const type = useMemo(() => maybeTypeFromId<EntityType>(id), [id]);
  const store = useStore(type || "task");
  return useMemo(
    () => (id ? persistedID(id, store.aliases) : undefined),
    [id, store.aliases]
  );
}
