import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { map } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  HasBlocked,
  HasTemplate,
  PropertyRef,
  PropertyType,
  PropertyValueRef,
  PropertyValue as PropertyValueType,
  Roadmap,
  View,
} from "@api";

import {
  useGetAllPropertyValues,
  useInflatedPropertyValue,
} from "@state/databases";
import { useQueueUpdates } from "@state/generic";

import { composel, Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import {
  toLabel,
  toPlaceholder,
  asValue,
  isEmptyValue,
} from "@utils/property-refs";
import { cx } from "@utils/class-names";
import { ensureArray, ensureMany } from "@utils/array";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { withHardHandle } from "@utils/event";
import { fromISO, toCalDate, toPointDate } from "@utils/date-fp";
import { useGoTo } from "@utils/navigation";
import { ComponentOrNode } from "@utils/react";

import { StatusTag, Tag, Tags } from "@ui/tag";
import { Button, Props as ButtonProps } from "@ui/button";
import { SectionLabel, Text } from "@ui/text";
import { TextInput } from "@ui/input";
import { DateInputPicker } from "@ui/date-picker";
import { StatusIcon } from "@ui/status-button";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import {
  TagMultiSelect,
  TagSelect,
  StatusSelect,
  LocationSelect,
  SelectProps,
  Option,
  GlobalEntitySelect,
  GlobalMultiEntitySelect,
  PersonSelect,
  PersonMultiSelect,
} from "@ui/select";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, Icon } from "@ui/icon";
import { Container } from "@ui/container";
import { TeamButton, TeamSelect } from "./team-select";
import { Label } from "@ui/label";
import { ScopedPropertySelect } from "@ui/select/property";
import { ScopedPropertiesSelect } from "@ui/select/properties";
import { PropertyRefLabel } from "@ui/property-def-label";
import { LocationButton } from "./location-button";
import { useCurrentPage } from "@ui/app-page";
import { ManageValuesFooter } from "@ui/select/footers";
import { EmojiSelect } from "@ui/select/emoji";
import { ScheduleButton, ScheduleEditor } from "@ui/engine/schedule";
import { LocationDialog } from "./location-dialog";

import styles from "./property-value.module.css";

export type PropertyTypeVariant =
  | "icon-only"
  | "no-icon"
  | "unlabelled"
  | "labelled";

export interface Props<E extends Entity> {
  valueRef: PropertyValueRef<E>;
  source: DatabaseID;
  parent?: Entity;
  children?: ReactNode;
  size?: ButtonProps["size"];
  fit?: ButtonProps["fit"];
  position?: SelectProps<Option>["position"];
  onChange?: Fn<PropertyValueRef<E>["value"], void>;
  variant?: PropertyTypeVariant;
  propTypeIcon?: boolean;
  inset?: boolean;
  className?: string;
  editing?: boolean;
  editable?: boolean;
  onClick?: Fn<React.MouseEvent, void>;
}

const isSortableProp = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  p?.field === "sort" || p?.field === "fields";

const isTeamRelation = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  p?.def?.options?.references === "team" ||
  ["team"].includes(p?.field as string) ||
  (p?.value.relation?.id || p?.value.relations?.[0]?.id)?.startsWith?.("tm_");

const isPersonRelation = <T extends Entity>(p: Maybe<PropertyValueRef<T>>) =>
  p?.def?.options?.references === "person" ||
  ["person", "assigned", "owner"].includes(p?.field as string) ||
  (p?.value.relation?.id || p?.value.relations?.[0]?.id)?.startsWith?.("u_");

function ReadControl<E extends Entity>({
  valueRef,
  source,
  parent,
  variant,
  propTypeIcon,
  editable,
  size = "small",
  fit,
  inset,
  onClick,
  className,
}: Props<E>) {
  const icon = propTypeIcon ? (
    <PropertyTypeIcon field={valueRef.field as string} type={valueRef.type} />
  ) : undefined;
  const { type, value } = valueRef;
  const goTo = useGoTo();

  // Location field is just stored as a text field but has a special picker
  if (valueRef.field === "location") {
    return (
      <LocationButton
        inset={true}
        fit={fit}
        variant={variant === "icon-only" ? "compact" : "default"}
        showCaret={false}
        location={valueRef.value.text}
        onClick={onClick}
      />
    );
  }

  const withWrapper = (children: ReactNode, iconOverride?: ComponentOrNode) => (
    <Button
      subtle
      className={cx(
        size === "tiny" && styles.noPadding,
        !editable && styles.disabled,
        className
      )}
      inset={inset}
      size={size}
      onClick={onClick}
      icon={iconOverride || icon}
    >
      {children}
    </Button>
  );

  if (!isTeamRelation(valueRef) && isEmptyValue(valueRef.value[type])) {
    // Empty property value
    return withWrapper(
      <Text className={styles.placeholder}>
        {toPlaceholder(valueRef, variant === "labelled", editable)}
      </Text>
    );
  }

  if (
    valueRef?.def?.options?.references === "schedule" ||
    valueRef?.field === "refs.repeat"
  ) {
    return (
      <ScheduleButton
        schedule={valueRef.value?.relation}
        subtle
        className={cx(!editable && styles.disabled, className)}
        inset={inset}
        size={size}
        onClick={onClick}
        icon={icon}
      />
    );
  }

  switch (type) {
    case "number":
      return withWrapper(toLabel(valueRef));

    case "text":
    case "email":
    case "url":
    case "title":
    case "phone":
      return withWrapper(value[type] || "None");

    case "rich_text":
      return withWrapper(value[type]?.text || value[type]?.markdown || "None");

    case "date":
      return withWrapper(
        <Label
          icon={
            variant !== "labelled" && variant !== "no-icon" ? (
              <PropertyTypeIcon
                field={valueRef.field as string}
                type={valueRef.type}
              />
            ) : undefined
          }
          text={toLabel(valueRef)}
        />
      );

    case "status":
      return withWrapper(
        variant !== "icon-only" && (
          <StatusTag
            status={value?.[type]}
            blocked={(parent as HasBlocked)?.blocked}
          >
            {value?.[type]?.name}
          </StatusTag>
        ),
        variant === "icon-only" ? (
          <StatusIcon
            status={value[type]}
            blocked={(parent as HasBlocked)?.blocked}
          />
        ) : undefined
      );

    case "select":
      return withWrapper(
        <Tag color={value?.[type]?.color}>{value?.[type]?.name}</Tag>
      );

    case "multi_select":
      return withWrapper(<Tags tags={value[type] || []} />);

    case "relation": {
      if (isTeamRelation(valueRef)) {
        return (
          <TeamButton
            className={cx(
              styles.value,
              !editable && styles.disabled,
              className
            )}
            subtle
            inset={inset}
            size={size}
            onClick={onClick}
            variant={variant === "icon-only" ? "icon-only" : "secondary"}
            caret={variant !== "icon-only"}
            icon={icon}
            team={valueRef?.value?.relation}
          />
        );
      }
    }

    case "relations":
    case "relation": {
      const relations = ensureArray(value[type]);

      return withWrapper(
        <Container
          gap={6}
          padding="none"
          stack={variant === "unlabelled" ? "horizontal" : "vertical"}
        >
          {map(relations, (ref) => (
            <SpaceBetween key={ref.id}>
              {variant === "icon-only" ||
              (variant !== "labelled" &&
                relations?.length > 1 &&
                valueRef?.def?.options?.references === "person") ? (
                <Icon icon={<RelationIcon relation={ref} />} />
              ) : (
                <RelationLabel relation={ref} />
              )}
              {variant === "labelled" && (
                <Button
                  className={styles.onHover}
                  size="tiny"
                  icon={ArrowUpRight}
                  onClick={withHardHandle(() => goTo(ref))}
                />
              )}
            </SpaceBetween>
          ))}
        </Container>
      );
    }

    case "property":
    case "properties": {
      const v = ensureArray(value[type]);
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;
      const propSource = { type: childType, scope: source.scope };

      return withWrapper(
        <Container gap={6} padding="none" stack={"horizontal"}>
          {v.length > 1 && (
            <>
              <PropertyRefLabel prop={v[0]} source={propSource} />
              <Label>+ {v.length - 1} other fields</Label>
            </>
          )}
          {v.length === 1 && (
            <PropertyRefLabel prop={v[0]} source={propSource} />
          )}
          {!v.length && <Text subtle>No fields.</Text>}
        </Container>
      );
    }

    default:
      return withWrapper("System value.");
  }
}

const WriteControl = <E extends Entity>({
  valueRef,
  parent,
  source,
  variant,
  onChange: _onChange,
  onClose,
  position,
  children,
}: Props<E> & { onClose: Fn<void, void> }) => {
  const pageId = useCurrentPage();
  const { values: options, fetch: fetchOptions } = useGetAllPropertyValues(
    source,
    valueRef
  );
  const queue = useQueueUpdates(pageId);
  // If no onChange is provided but it's editable, we will queue the update ourselves
  const onChange = useCallback(
    _onChange ||
      ((v: PropertyValueRef["value"]) => {
        if (!parent || !source) {
          return;
        }

        queue(
          asUpdate<Entity>(
            { id: parent.id, source: source } as Pick<Entity, "id" | "source">,
            asMutation(valueRef as PropertyRef, v[valueRef.type])
          )
        );
      }),
    [queue, parent?.id, source, _onChange]
  );

  const { type, value } = valueRef;
  const asPropValue = useCallback(
    <T extends PropertyType>(v: PropertyValueType[T]) => asValue(type, v),
    [type]
  );

  useEffect(() => {
    fetchOptions();
  }, []);

  // Location field is just stored as a text field but has a special picker
  if (valueRef.field === "location") {
    return parent ? (
      <LocationDialog
        suggested={valueRef.value.text}
        targets={[{ id: parent.id }]}
        onComplete={onClose}
        onCancel={onClose}
      />
    ) : (
      <LocationSelect
        location={valueRef.value.text}
        source={source}
        open={true}
        inset={true}
        position={position}
        variant={variant === "icon-only" ? "compact" : "default"}
        showCaret={variant !== "icon-only"}
        setOpen={(c) => c === false && onClose?.()}
        onChange={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
        children={children}
      />
    );
  }

  // Schedule picker
  if (valueRef.field === "refs.repeat" && !!parent) {
    return (
      <ScheduleEditor
        schedule={valueRef.value.relation}
        parentId={parent.id}
        source={source}
        position={position}
        portal={true}
        open={true}
        setOpen={(c) => c === false && onClose?.()}
        onChanged={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
        children={children}
      />
    );
  }

  if (valueRef.field === "icon") {
    return (
      <EmojiSelect
        children={children}
        emoji={valueRef.value.text || ""}
        onChange={(v) => {
          onChange?.(asPropValue(v));
          onClose?.();
        }}
      />
    );
  }

  switch (type) {
    case "text":
    case "email":
    case "url":
    case "title":
    case "phone":
      return (
        <TextInput
          autoFocus
          value={value[type] || ""}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
        />
      );

    case "rich_text":
      throw new Error("Rich write property control not implemented.");
      return <></>;

    case "select":
      return (
        <TagSelect
          value={value[type]}
          portal={true}
          options={options[type] || []}
          position={position}
          createable={valueRef.def?.locked !== true}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          onBlur={onClose}
          open={true}
          setOpen={() => onClose()}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
          children={children}
        />
      );

    case "status":
      return (
        <StatusSelect
          value={value[type]}
          portal={true}
          options={options[type] || []}
          onChange={(v) => {
            onChange?.(asPropValue(v));
            onClose?.();
          }}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          position={position}
          onBlur={onClose}
          open={true}
          setOpen={() => onClose()}
          children={children}
        />
      );

    case "multi_select":
      return (
        <TagMultiSelect
          open={true}
          setOpen={() => onClose()}
          portal={true}
          position={position}
          value={value[type]}
          footer={
            <ManageValuesFooter {...source} prop={valueRef as PropertyRef} />
          }
          createable={valueRef.def?.locked !== true}
          options={options[type] || []}
          onChange={(vs) => onChange?.({ [type]: vs })}
          onBlur={onClose}
          children={children}
        />
      );

    case "relation": {
      const v = value[type];

      if (isTeamRelation(valueRef)) {
        return (
          <TeamSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            position={position}
            team={v}
            onChanged={(u) => onChange?.({ [type]: u })}
            onBlur={onClose}
            children={children}
          />
        );
      }

      if (isPersonRelation(valueRef)) {
        return (
          <PersonSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            position={position}
            value={v}
            onChange={(u) => onChange?.({ [type]: u })}
            onBlur={onClose}
            children={children}
          />
        );
      }

      const references = (valueRef.def?.options?.references ||
        "task") as EntityType;

      return (
        <GlobalEntitySelect
          open={true}
          setOpen={() => onClose()}
          portal={true}
          position={position}
          value={v}
          showOtherTeams={true}
          type={references}
          scope={source.scope}
          templates={!!(parent as Maybe<HasTemplate>)?.template}
          allowed={[references]}
          onChange={(u) => onChange?.({ [type]: u })}
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "relations": {
      const v = ensureArray(value[type]);

      if (isPersonRelation(valueRef)) {
        return (
          <PersonMultiSelect
            open={true}
            setOpen={() => onClose()}
            portal={true}
            position={position}
            value={v}
            onChange={(u) => onChange?.({ [type]: u })}
            onBlur={onClose}
            children={children}
          />
        );
      }

      const references = (valueRef.def?.options?.references ||
        "task") as EntityType;

      return (
        <GlobalMultiEntitySelect
          open={true}
          setOpen={() => onClose()}
          value={v}
          portal={true}
          position={position}
          closeOnSelect={false}
          type={references}
          scope={source.scope}
          templates={!!(parent as Maybe<HasTemplate>)?.template}
          allowed={[references]}
          showOtherTeams={true}
          onChange={(u) => onChange?.(asPropValue(u))}
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "properties": {
      const v = value[type];
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;
      return (
        <ScopedPropertiesSelect
          open={true}
          setOpen={() => onClose()}
          value={v}
          source={{ type: childType, scope: source.scope }}
          portal={true}
          position={position}
          blacklist={when(valueRef.field, composel(String, ensureMany))}
          closeOnSelect={false}
          onChanged={(vs) =>
            onChange?.(
              asPropValue(
                isSortableProp(valueRef)
                  ? map(vs, (i) => ({
                      ...i,
                      direction: i.direction || "asc",
                    }))
                  : vs
              )
            )
          }
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "property": {
      const v = value[type];
      const childType = ((parent as Maybe<View>)?.entity ||
        (parent as Maybe<Roadmap>)?.settings?.child_type ||
        source.type) as EntityType;

      return (
        <ScopedPropertySelect
          open={true}
          setOpen={() => onClose()}
          value={v}
          type={childType}
          scope={source.scope}
          portal={true}
          position={position}
          blacklist={when(valueRef.field, composel(String, ensureMany))}
          closeOnSelect={false}
          onChanged={(u) => onChange?.(asPropValue(u))}
          onBlur={onClose}
          children={children}
        />
      );
    }

    case "person": {
      // Only show/set one person for assigned
      throw new Error("Person not supported anymore.");
    }

    case "date":
      return (
        <DateInputPicker
          date={when(value[type], fromISO)}
          required={false}
          open={true}
          portal={true}
          onChanged={(d) =>
            onChange?.(
              !d
                ? asPropValue(undefined)
                : asPropValue(
                    valueRef.def?.options?.mode === "point"
                      ? toPointDate(d)
                      : toCalDate(d, "local")
                  )
            )
          }
          onClose={onClose}
          children={children}
        />
      );

    case "number":
      return (
        <TextInput
          autoFocus
          value={String(value[type] || "")}
          inputType={"number"}
          onChange={(v) => {
            onChange?.(asPropValue(Number(v)));
            onClose?.();
          }}
        />
      );
    default: {
      throw new Error("Unsupported property type.");
    }
  }
};

export function PropertyValue<E extends Entity>({
  className,
  children,
  onClick,
  editing: defaultEditing,
  editable = true,
  propTypeIcon = false,
  variant = "unlabelled",
  ...props
}: Props<E>) {
  const [editing, setEditing] = useState(defaultEditing ?? false);

  const valueRef = useInflatedPropertyValue(props.valueRef, props.source);

  const readControl = useMemo(
    () =>
      children ? (
        <div onClick={() => setEditing(true)}>{children}</div>
      ) : (
        <ReadControl
          {...props}
          propTypeIcon={propTypeIcon}
          editable={editable}
          variant={variant}
          valueRef={valueRef}
          onClick={onClick || (() => editable && setEditing(true))}
          className={className}
        />
      ),
    [
      children,
      props,
      propTypeIcon,
      editable,
      variant,
      valueRef,
      onClick,
      className,
    ]
  );

  return (
    <div className={cx(styles.container, className)}>
      {!editing && readControl}
      {editing && (
        <WriteControl
          {...props}
          valueRef={valueRef}
          variant={variant}
          onClose={() => setEditing(false)}
          onChange={props.onChange}
          children={readControl}
        />
      )}
    </div>
  );
}

export const LabelledValue = ({
  children,
  label,
  ...props
}: {
  children: ReactNode;
  fit?: ButtonProps["fit"];
  label: string | ReactNode;
}) => (
  <VStack gap={0} fit={props.fit}>
    <SectionLabel subtle className={styles.noBreak}>
      {label}
    </SectionLabel>
    {children}
  </VStack>
);

export const LabelledPropertyValue = <E extends Entity>({
  label,
  className,
  fit,
  ...props
}: Props<E> & { label: string }) =>
  label ? (
    <LabelledValue label={label} fit={fit}>
      <PropertyValue
        {...props}
        className={cx(className, styles.fullWidth)}
        inset={true}
        variant="labelled"
      />
    </LabelledValue>
  ) : (
    <PropertyValue
      {...props}
      fit={fit}
      className={className}
      inset={true}
      variant="unlabelled"
    />
  );
