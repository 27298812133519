import { map } from "lodash";

import { Page } from "@api";

import { useVisiblePropertyRefs } from "@state/databases";

import { cx } from "@utils/class-names";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";

import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text } from "@ui/text";
import { PropertyValue } from "@ui/property-value";
import { SmartLocationLabel } from "@ui/location-button";
import { DocumentInfo, Icon, iconFromString } from "@ui/icon";

import { ListItemOpts, ViewEngine } from "../types";
import { PagePane } from "./pane";

import styles from "./styles.module.css";

// Export individual components

export * from "./pane";
export * from "./create-dialog";

export const PageEngine: ViewEngine<Page> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          className={styles.relative}
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
        >
          <SpaceBetween>
            <HStack align="stretch">
              <HStack gap={4}>
                <Icon icon={when(item.icon, iconFromString) || DocumentInfo} />
                <Text>{item.title || "Untitled page"}</Text>
              </HStack>
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <SpaceBetween direction="horizontal" gap={6} align="stretch">
            <VStack className={styles.bar} gap={0}>
              <SpaceBetween>
                <Ellipsis>
                  <HStack gap={4}>
                    <Icon
                      icon={when(item.icon, iconFromString) || DocumentInfo}
                    />
                    <Text>{item.title || "Untitled page"}</Text>
                  </HStack>
                </Ellipsis>
              </SpaceBetween>
            </VStack>
          </SpaceBetween>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props: ListItemOpts<Page>) {
    const { item, showProps, variant, onChange } = props;
    const { visible } = useVisiblePropertyRefs(item, showProps, {
      blacklist: ["name", "status", "start", "end"],
      hideEmpty: props.hideEmpty,
    });
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem
          {...props}
          className={cx(styles.relative, props.className)}
        >
          <SpaceBetween className={styles.upper} fit="container">
            <HStack gap={4}>
              <Icon icon={when(item.icon, iconFromString) || DocumentInfo} />

              <Text>{item.title || "Untitled page"}</Text>

              <SmartLocationLabel
                location={item.location}
                showTeam={false}
                showIcons={false}
                size="small"
                subtle
              />
            </HStack>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              {map(visible, (val) => (
                <PropertyValue
                  key={val.field}
                  valueRef={val}
                  source={item.source}
                  variant={variant || "unlabelled"}
                  onChange={(c) =>
                    onChange?.({
                      field: val.field,
                      type: val.type,
                      value: c,
                      prev: val.value,
                    })
                  }
                />
              ))}
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asPrimaryPane: PagePane,
};
