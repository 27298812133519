import { markdownToTxt } from "markdown-to-txt";
import markdownit from "markdown-it";
import { jsonrepair } from "jsonrepair";
import markdownitTable from "markdown-it-multimd-table";
import { NodeHtmlMarkdown } from "node-html-markdown";

import { Maybe, maybeMap } from "./maybe";
import { replaceAll_ } from "./string";
import { composel } from "./fn";

import markdownitCheckbox from "./markdown-tasklist-plugin";
import asidePlugin from "./markdown-aside-plugin";

// Setup markdownit with the checkbox and table plugins
const md = markdownit()
  .use(markdownitCheckbox)
  .use(markdownitTable)
  .use(asidePlugin);

export const toPlainText = (markdown: string) => markdownToTxt(markdown);

// Utility function to check if the content is markdown
export const isMarkdown = (text: string): boolean =>
  /(^#)|(\*\*)|(```)|(- )/m.test(text);

export const toHtml = (markdown: string) => {
  return md.render(markdown);
};

export const toMarkdown = (html: string) => {
  return NodeHtmlMarkdown.translate(html, {});
};

export function extractJSON(markdown: string): string[] {
  const jsonRegex = /```json([\s\S]*?)```/g;
  const jsonMatches: string[] = [];
  let match;

  while ((match = jsonRegex.exec(markdown)) !== null) {
    // Extract the JSON content (group 1)
    const jsonContent = match[1].trim();
    jsonMatches.push(jsonContent?.replaceAll("\n", ""));
  }

  return jsonMatches;
}

export const cleanFormattedMarkdown = (markdown: string) =>
  markdown.replace(/```markdown/g, "").replace(/```/g, "");

// Function that takes an AI streamed response that has not finished returning and tries to return the most complete amount of JSON content
// This function is not perfect and may return incomplete JSON content
export function optimisticExtractJSON(markdown: string): string {
  // Remove any opening or trailing markdown ```json or ```
  const cleanedMarkdown = markdown.replace(/```json/g, "").replace(/```/g, "");
  try {
    return jsonrepair(cleanedMarkdown);
  } catch (err) {}
  return "";
}

const replaceUnsafe = composel(
  replaceAll_("[", "%5B"),
  replaceAll_("]", "%5D"),
  replaceAll_(",", "%2C")
);
export const toMarkdownLink = ({
  text,
  url,
}: {
  text: Maybe<string>;
  url: string;
}) => `[${replaceUnsafe(text || "")}](${url})`;
