import { useRecoilValue } from "recoil";
import {
  filter,
  first,
  intersection,
  last,
  map,
  omit,
  uniq,
  without,
} from "lodash";
import { useCallback, useMemo, useState } from "react";

import {
  DatabaseID,
  Entity,
  EntityType,
  Form,
  hasIcon,
  HasLocation,
  isBaseScopeEntity,
  Ref,
  toTitleOrName,
} from "@api";

import { currentPage, useSyncPageSelection } from "@state/app";
import { useInstalledEntities } from "@state/packages";
import {
  useCreateFromObject,
  useCreateFromTemplate,
  useLazyEntities,
  useLazyEntity,
} from "@state/generic";
import { useLazyAllTeams } from "@state/teams";
import { useLazyTemplates } from "@state/templates";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useEntityLabel, useEntityLabels } from "@state/settings";
import { useFormsForLocation, FormData } from "@state/form";
import { useAllowedChildren } from "@state/databases";

import { useShowMore } from "@utils/hooks";
import { OneOrMany, ensureArray, ensureMany, omitEmpty } from "@utils/array";
import { Fn } from "@utils/fn";
import { useGoTo } from "@utils/navigation";
import { Maybe, maybeMap, when } from "@utils/maybe";
import {
  PageSelectionContext,
  SelectionState,
  SetSelectionState,
  useKeyboardSelectable,
  useMouseSelectable,
  useSelectableState,
} from "@utils/selectable";
import {
  fromLocation,
  fromScope,
  joinLocation,
  toBaseScope,
} from "@utils/scope";
import { isHumanId, isWorkspaceId } from "@utils/id";
import { toRef } from "@utils/property-refs";

import { Container } from "@ui/container";
import { Props as DialogProps, DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { getEngine, render, useEngine } from "@ui/engine";
import { FormSubmit } from "@ui/engine/form";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowDownRight,
  ArrowLeft,
  ArrowUpLeft,
  ArrowUpRight,
  ClipboardNotes,
  EyeSlash,
  Icon,
  iconFromString,
  PaintTool,
  PlusAlt,
  TimesIcon,
} from "@ui/icon";
import { ListCard } from "@ui/list-card";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import {
  CheckMenuItem,
  EmptyMenuItem,
  MenuItem,
  ShowMoreMenuItem,
} from "@ui/menu-item";
import { PeopleStack } from "@ui/people-stack";
import { Text, TextXLarge } from "@ui/text";
import { showError } from "@ui/notifications";
import { useCurrentPage } from "@ui/app-page";
import { MouseSelection } from "@ui/mouse-selection";
import { Button, Props as ButtonProps } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { LocationSelect } from "@ui/select";
import { EntityList, Props as EntityListProps } from "./entity-list";
import { useSelectionShortcuts } from "./selection-shortcuts";
import { ActionGroup, ActionItem, ActionMenu } from "@ui/action-menu";
import { Divider } from "@ui/divider";
import { Label } from "@ui/label";
import { RelationLabel } from "./relation-label";

import styles from "./add-work-dialog.module.css";

interface Props {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  allowed?: EntityType[];
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
  onModeChanged?: Fn<DialogProps["mode"], void>;
}

export const AddWorkDialog = ({
  onCancel,
  allowed,
  onSaved,
  defaults,
}: Props) => {
  const pageId = useCurrentPage();
  const wID = useActiveWorkspaceId();
  const goTo = useGoTo();
  const [location, setLocation] = useState<Maybe<string>>(
    (defaults as HasLocation)?.location
  );
  const [dialogMode, setDialogMode] = useState<DialogProps["mode"]>("blocking");
  const [mode, setMode] = useState<"team-select" | "creating">(
    !!location ? "creating" : "team-select"
  );
  const creatingIn = useLazyEntity(last(fromScope(location || wID)));
  const [fromForm, setFromForm] = useState<Maybe<Form>>();
  const allForms = useFormsForLocation(creatingIn);
  const forms = useShowMore(allForms, 5);

  const [selection, setSelection] = useSelectableState();
  useKeyboardSelectable(selection, setSelection);
  const drag = useMouseSelectable(selection, setSelection);
  useSelectionShortcuts(pageId || "", {
    selection,
    setSelection,
    onOpen: goTo,
  });
  useSyncPageSelection(pageId || "", selection);

  const selectionState = useMemo(
    () => [selection, setSelection] as [SelectionState, SetSelectionState],
    [selection, setSelection]
  );

  const menuControl = (
    <Menu>
      <Text className={styles.spaceBottom} subtle>
        Quickly create any work for your team.
      </Text>

      <MenuGroup>
        <MenuItem
          selected={!fromForm}
          icon={PlusAlt}
          text={"From empty"}
          onClick={() => setFromForm(undefined)}
        />
      </MenuGroup>

      <MenuGroup label="Submit a form">
        {map(forms.visible, (f) => (
          <MenuItem
            key={f.id}
            selected={fromForm?.id === f.id}
            icon={iconFromString(f.icon) || ClipboardNotes}
            text={f.name}
            onClick={() => setFromForm(f)}
          />
        ))}

        {forms?.hasMore && (
          <ShowMoreMenuItem count={forms.moreCount} onClick={forms.showMore} />
        )}

        {!forms?.visible?.length && <EmptyMenuItem>No forms</EmptyMenuItem>}
      </MenuGroup>
    </Menu>
  );

  return (
    <DialogSplit
      mode={dialogMode}
      title={
        <SpaceBetween>
          <TextXLarge bold={true}>Add work</TextXLarge>
          {mode !== "team-select" && (
            <Button
              subtle
              size="small"
              icon={ArrowLeft}
              onClick={() => setMode("team-select")}
            />
          )}
        </SpaceBetween>
      }
      onDismiss={onCancel}
      className={{
        modal: styles.modal,
        left: mode === "creating" ? styles.leftThicc : styles.left,
        right: styles.right,
      }}
      side={
        <SpaceBetween direction="vertical">
          {mode === "creating" ? (
            menuControl
          ) : (
            <Text subtle>Quickly add tasks to any team or project.</Text>
          )}
          <EducationTip tip="You can quickly assign tasks by @mentioning the person in the task title." />
        </SpaceBetween>
      }
      actions={<></>}
    >
      <PageSelectionContext.Provider value={selectionState}>
        <MouseSelection {...drag} />

        <FillSpace>
          {mode === "team-select" && (
            <TeamSelecting
              onLocation={(l) => {
                setLocation(l);
                setMode("creating");
              }}
            />
          )}
          {mode === "creating" && (
            <VStack>
              <SpaceBetween>
                <LocationSelect
                  inset={true}
                  location={location}
                  onChange={setLocation}
                  source={undefined}
                  showOpen={false}
                  variant="default"
                  showCaret={true}
                />

                <HStack gap={0}>
                  {dialogMode === "blocking" && (
                    <Button
                      icon={ArrowDownRight}
                      subtle
                      onClick={() => setDialogMode?.("passive")}
                    />
                  )}
                  {dialogMode === "passive" && (
                    <Button
                      icon={ArrowUpLeft}
                      subtle
                      onClick={() => setDialogMode?.("blocking")}
                    />
                  )}
                  <Button
                    icon={TimesIcon}
                    subtle
                    onClick={() => onCancel?.()}
                  />
                </HStack>
              </SpaceBetween>

              {!fromForm && (
                <CreatingWork
                  location={location || ""}
                  allowed={allowed}
                  defaults={defaults}
                  onSaved={onSaved}
                  onCancel={onCancel}
                />
              )}

              {!!fromForm && (
                <SubmittingForm
                  form={fromForm}
                  defaults={defaults}
                  onSaved={onSaved}
                  onCancel={onCancel}
                />
              )}
            </VStack>
          )}
        </FillSpace>
      </PageSelectionContext.Provider>
    </DialogSplit>
  );
};

const SubmittingForm = ({
  form,
  onSaved,
  onCancel,
  defaults,
}: { form: Form } & Props) => {
  const [data, setData] = useState<FormData>(
    () => omit(defaults, "source") || {}
  );

  return (
    <VStack gap={20} fit="container">
      <SpaceBetween>
        <Label
          icon={iconFromString(form.icon) || ClipboardNotes}
          bold
          size="medium"
        >
          {form.name}
        </Label>
      </SpaceBetween>

      <Divider />

      <FormSubmit
        form={form}
        data={data}
        onChanged={setData}
        onSubmitted={(r) => onSaved?.(ensureMany(r))}
      />
    </VStack>
  );
};

const CreatingWork = ({
  location,
  defaults,
  mode,
  allowed,
  onModeChanged,
  onCancel,
}: Props & { location: string; mode?: DialogProps["mode"] }) => {
  const goTo = useGoTo();
  const workspaceId = useActiveWorkspaceId();
  const baseScopeId = useMemo(() => first(fromScope(location)), [location]);
  const scope = useMemo(() => fromLocation(location, workspaceId), [location]);
  const installed = useInstalledEntities(baseScopeId || "");
  const addingTo = useLazyEntity(
    useMemo(() => last(fromScope(location)), [location])
  );
  const allowedChildren = useAllowedChildren(addingTo);

  const types = useMemo(
    () =>
      allowed ||
      filter(
        when(addingTo?.source.type, isBaseScopeEntity)
          ? // Teams/people can add anything inside them
            installed
          : // Everything else can only add what has child relations for
            intersection(allowedChildren, installed),
        (t) => !getEngine(t)?.asCreateDialog
      ),
    [allowed, installed]
  );
  const [adding, setAdding] = useState<EntityType[]>(
    () => when(allowed?.[0], ensureArray) || []
  );
  const buttons = useMemo(() => without(types, ...adding), [types, adding]);
  const toEntityLabel = useEntityLabels(location);

  return (
    <VStack gap={10}>
      {map(adding, (type) => (
        <>
          <CollapsibleSection
            key={type}
            title={`Add ${toEntityLabel(type, { plural: true })}`}
            divider={false}
          >
            <AddItemsList
              scope={scope}
              type={type}
              location={location}
              defaults={defaults}
              onOpen={(e) => {
                goTo(e);
                onModeChanged?.("passive");
              }}
            />
          </CollapsibleSection>
          <Divider />
        </>
      ))}

      <Container inset="left" padding="none">
        <VStack fit="container" scrollable gap={0}>
          {map(buttons, (type) => (
            <Button
              key={type}
              icon={PlusAlt}
              fit="container"
              subtle
              size="small"
              onClick={() => setAdding((a) => uniq([...a, type]))}
            >
              {toEntityLabel(type)}
            </Button>
          ))}
        </VStack>
      </Container>
    </VStack>
  );
};

const AddItemsList = ({
  type,
  scope,
  location,
  defaults,
  onOpen,
}: {
  type: EntityType;
  scope: string;
  location: string;
  defaults: Props["defaults"];
  onOpen?: Fn<Ref, void>;
}) => {
  const [created, setCreated] = useState<Ref[]>([]);
  const items = useLazyEntities(created);
  const source = useMemo(() => ({ scope, type }), [scope, type]);
  const onAdded = useCallback(
    (cs: OneOrMany<Ref>) => setCreated((c) => [...c, ...ensureMany(cs)]),
    [setCreated]
  );

  const addButtonOpts = useMemo(
    () =>
      ({
        defaults: { ...defaults, location: location },
        onAdded: onAdded,
        autoFocus: true,
        canLink: false,
        source,
      } as EntityListProps["addButton"]),
    [defaults, location, onAdded]
  );

  return (
    <EntityList
      showCode={false}
      variant="icon-only"
      onOpen={onOpen}
      items={items || []}
      addButton={addButtonOpts}
    />
  );
};

const TeamSelecting = ({
  onLocation,
}: {
  onLocation: Fn<Maybe<string>, void>;
}) => {
  const me = useCurrentUser();
  const page = useRecoilValue(currentPage);
  const [saveAsDefault, setSaveAsDefault] = useState(false);
  const teams = useLazyAllTeams();
  const engine = useEngine("team");
  const suggestable = useMemo(() => {
    const locationParts =
      when(page?.entity?.source?.scope, (s) =>
        omitEmpty([...fromScope(s), page?.entity?.id])
      ) || when(page?.path, (p) => filter(fromScope(p), isHumanId));

    return maybeMap(locationParts, (i) =>
      isWorkspaceId(i) ? undefined : toRef(i)
    );
  }, [page?.entity?.source?.scope, page?.path]);
  const suggestions = useLazyEntities(suggestable);

  return (
    <VStack fit="container" gap={30}>
      <SpaceBetween fit="container">
        <Text>Where do you want to create this work?</Text>
        <span>
          <CheckMenuItem checked={saveAsDefault} onChecked={setSaveAsDefault}>
            Save as default location
          </CheckMenuItem>
        </span>
      </SpaceBetween>

      {!!suggestions?.length && (
        <>
          <HStack scrollable fit="container" gap={4}>
            <Text subtle>Suggestions:</Text>
            {map(suggestions, (s) => (
              <Button
                onClick={() =>
                  onLocation(
                    isBaseScopeEntity(s.source.type)
                      ? s.id
                      : joinLocation(s.source.scope, s.id)
                  )
                }
              >
                <RelationLabel relation={s} />{" "}
              </Button>
            ))}
          </HStack>
          <Divider />
        </>
      )}

      <VStack gap={10}>
        <ListCard
          padding="none"
          selectable={false}
          onClick={() => onLocation?.(me.id)}
        >
          <Container fit="container" stack="vertical" gap={10}>
            <SpaceBetween>
              <HStack>
                <Icon icon={EyeSlash} />

                <TextXLarge bold>Private</TextXLarge>
              </HStack>

              <PeopleStack people={[me]} />
            </SpaceBetween>

            <Text subtle>Nobody else will see this work.</Text>
          </Container>
        </ListCard>

        {map(teams, (t) =>
          render(engine.asListCard, {
            key: t.id,
            item: t,
            onOpen: (t) => onLocation(t.id),
          })
        )}
      </VStack>
    </VStack>
  );
};

// TODO: Can remove, this doesn't do anything anymore
export const SmartDefaultsAddWorkDialog = ({ defaults, ...props }: Props) => {
  return <AddWorkDialog {...props} defaults={defaults} />;
};

export const AddWorkButton = (props: ButtonProps & Props) => {
  const {
    defaults,
    onSaved,
    onCancel,
    allowed,
    onModeChanged,
    ...buttonProps
  } = props;
  const dialogProps = { defaults, onSaved, onCancel, allowed, onModeChanged };
  const [showing, setShowing] = useState(false);
  const type = props.defaults?.source?.type;
  const engine = useEngine(type);

  return (
    <>
      {showing && !engine?.asCreateDialog && (
        <AddWorkDialog {...dialogProps} onCancel={() => setShowing(false)} />
      )}

      {showing &&
        !!engine?.asCreateDialog &&
        render(engine?.asCreateDialog, {
          ...dialogProps,
          onSaved: (r) => {
            onSaved?.(r);
            setShowing(false);
          },
          onCancel: () => setShowing(false),
        })}

      <Button
        size="small"
        icon={PlusAlt}
        {...buttonProps}
        onClick={() => setShowing(true)}
      />
    </>
  );
};

type ActionMenuProps = {
  defaults:
    | { source?: DatabaseID<EntityType> }
    | Partial<HasLocation>
    | Partial<Entity>;
  onSaved?: Fn<OneOrMany<Ref>, void>;
} & Omit<ButtonProps, "onClick">;

export const AddWorkActionMenu = ({
  defaults,
  onSaved,
  children,
  ...rest
}: ActionMenuProps) => {
  const pageId = useCurrentPage();
  const wId = useActiveWorkspaceId();
  const source = useMemo(
    () => defaults.source || ({ type: "task", scope: wId } as DatabaseID),
    [defaults.source]
  );
  const engine = useEngine(source.type);
  const [showCreate, setShowCreate] = useState(false);
  const create = useCreateFromObject(source.type, source.scope, pageId);
  const createTemplate = useCreateFromTemplate(source, onSaved);
  const goTo = useGoTo();
  const label = useEntityLabel(source.type, source.scope, {
    case: "lower",
  });
  const templates = useLazyTemplates(source);

  const handleCreateBlank = useCallback(() => {
    if (engine.asCreateDialog) {
      setShowCreate(true);
      return;
    }

    if (!create) {
      return showError("Not ready.");
    }

    const [neww] = create([
      { source: source, ...defaults, name: "" } as Partial<Entity>,
    ]);
    onSaved?.(neww);
  }, [create, engine]);

  const handleCreateFromTemplate = useCallback(
    (template: Ref) => {
      if (!createTemplate.ready) {
        return showError("Not ready.");
      }

      createTemplate.create(template, {
        overrides: { [template.id]: { ...omit(defaults, "source"), name: "" } },
      });
    },
    [createTemplate.ready]
  );

  if (!!engine?.asCreateDialog) {
    return (
      <>
        <div onClick={() => setShowCreate(true)}>
          {children || (
            <Button icon={PlusAlt} loading={createTemplate.loading} {...rest}>
              <Text subtle>New {label}</Text>
            </Button>
          )}
        </div>

        {showCreate &&
          !!engine?.asCreateDialog &&
          render(engine?.asCreateDialog, {
            defaults,
            onSaved: (r) => {
              onSaved?.(r);
              setShowCreate(false);
            },
            onCancel: () => setShowCreate(false),
          })}
      </>
    );
  }

  return (
    <>
      <ActionMenu
        actions={
          <>
            <ActionItem icon={PlusAlt} onClick={handleCreateBlank}>
              From empty
            </ActionItem>

            <ActionGroup label="From template">
              {map(templates, (template: Entity) => (
                <ActionItem
                  key={template.id}
                  icon={
                    (template &&
                      hasIcon(template) &&
                      iconFromString(template.icon)) ||
                    PaintTool
                  }
                  onClick={() => handleCreateFromTemplate(template)}
                >
                  {toTitleOrName(template)}
                </ActionItem>
              ))}
            </ActionGroup>

            <ActionItem
              onClick={() =>
                goTo([toBaseScope(source.scope), "/settings/templates"])
              }
              iconRight={ArrowUpRight}
            >
              <Text subtle>Manage Templates</Text>
            </ActionItem>
          </>
        }
      >
        {children || (
          <Button icon={PlusAlt} loading={createTemplate.loading} {...rest}>
            <Text subtle>New {label}</Text>
          </Button>
        )}
      </ActionMenu>
    </>
  );
};
