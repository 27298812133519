import { useLocation, useSearchParams } from "react-router-dom";
import { every, isArray, isFunction, take } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
export { useDebouncedCallback } from "use-debounce";

import { JsonObject } from "@api";

import { storage } from "@state/storage";
import { useActiveWorkspaceId } from "@state/workspace";

import { Maybe, Primitive, isDefined, safeAs, when } from "./maybe";
import { Fn, isFunc, using } from "./fn";
import { useWindowEvent } from "./event";
import { omitEmpty } from "./object";
import { OneOrMany, ensureArray } from "./array";
import { equalsAny } from "./logic";

function isOrIsDescendant(
  parentElement: HTMLElement,
  childElement: HTMLElement
) {
  let node: Maybe<HTMLElement | ParentNode> = childElement;
  while (node) {
    if (node === parentElement) {
      return true;
    }

    if (!node.parentNode && node.nodeName !== "#document") {
      return undefined;
    }

    node = node.parentNode ?? undefined;
  }
  return false;
}

export const usePrevious = <T>(value: T) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T | undefined>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useRevertable = <T>(
  def: T,
  revertAfter: number
): [T, Fn<T, void>] => {
  const [v, setV] = useState(def);
  const setter = useCallback(
    (v: T) => {
      setV(v);
      setTimeout(() => {
        setV(def);
      }, revertAfter);
    },
    [def, revertAfter]
  );

  return [v, setter];
};

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

export const useClickAway = (
  refs: OneOrMany<RefObject<Maybe<HTMLElement>>>,
  onClickAway: Fn<void, void>,
  listening: boolean | Fn<void, boolean> = true,
  deps: any[] = []
) => {
  if (typeof window === "undefined") {
    return;
  }

  useWindowEvent(
    "mouseup",
    (e: MouseEvent) => {
      if (
        (isFunction(listening) ? listening() : listening) &&
        e.target &&
        // Check if not any parent is data-radix-popover-wrapper
        !safeAs<HTMLElement>(e.target)?.closest(
          "[data-radix-popper-content-wrapper], [data-tippy-root]"
        ) &&
        // Check that it's not the root html/body (non-attached elements do this)
        !equalsAny(safeAs<HTMLElement>(e.target)?.nodeName, ["HTML", "BODY"]) &&
        // Not a descendant of refs
        every(
          ensureArray(refs),
          (ref) =>
            ref.current &&
            isOrIsDescendant(ref.current, e.target as HTMLElement) === false
        )
      ) {
        onClickAway();
      }
    },
    false,
    deps
  );
};

export function useQueryParams() {
  const location = useLocation();

  return useMemo(
    // Use window.location instead of above location to avoid issues with nested routes
    () => Object.fromEntries(new URLSearchParams(window.location.search)),
    [location]
  );
}

export function useSetQueryParams() {
  const [_searchParams, setSearchParams] = useSearchParams();

  // Updates the query params
  return useCallback(
    (params: Record<string, Maybe<string>>) =>
      setSearchParams(omitEmpty(params) as Record<string, string>),
    [history]
  );
}

const useSubStorage = (key: string) => {
  const workspaceId = useActiveWorkspaceId();
  const storageKey = useMemo(() => `${key}.${workspaceId}`, [workspaceId]);

  const getItem = useCallback(
    (key: string) => {
      const state = when(storage().getItem(storageKey), JSON.parse) || {};
      return state[key];
    },
    [storageKey]
  );
  const setItem = useCallback(
    (key: string, value: Maybe<Primitive | JsonObject>) => {
      const state = when(storage().getItem(storageKey), JSON.parse) || {};
      if (!isDefined(value)) {
        delete state[key];
      } else {
        state[key] = value;
      }
      storage().setItem(storageKey, JSON.stringify(state));
    },
    [storageKey]
  );

  return { getItem, setItem };
};

export const useStickyState = <T extends Primitive | JsonObject>(
  defaultVal: T | Fn<void, T>,
  key: string,
  parse: Fn<Primitive | JsonObject, Maybe<T>> = (x) => x as T
) => {
  const substorage = useSubStorage("traction.client.sticky");
  const [value, setValue] = useState<T>(() => {
    const stored = substorage.getItem(key);
    return (
      when(stored, parse) || (isFunc(defaultVal) ? defaultVal() : defaultVal)
    );
  });

  const setStickyValue = useCallback(
    (val: T) => {
      substorage.setItem(key, val);
      setValue(val);
    },
    [setValue, key]
  );

  // Reload whenever key changes, duplicated default state to keep type signature non-maybe
  useEffect(() => {
    const stored = substorage.getItem(key);
    const val =
      when(stored, parse) || (isFunc(defaultVal) ? defaultVal() : defaultVal);

    setValue(val);
  }, [key]);

  return [value, setStickyValue] as const;
};

export const useRefState = <T extends Primitive>(defaultVal: T) => {
  const ref = useRef<T>(defaultVal);
  const [value, _setValue] = useState<T>(defaultVal);
  const setAction = useCallback(
    (t: T) => {
      _setValue(t);
      ref.current = t;
    },
    [ref, _setValue]
  );
  return [value, ref, setAction] as const;
};

export const useStateCallback = <T extends Primitive>(
  defaultVal: T,
  callback?: Fn<T, void>
) => {
  const [value, _setValue] = useState<T>(defaultVal);
  const setAction = useCallback(
    (t: T | SetStateAction<T>) => {
      _setValue(t);
      if (callback) {
        callback(isFunc(t) ? t(value) : t);
      }
    },
    [callback, _setValue]
  );
  return [value, setAction] as [T, Dispatch<SetStateAction<T>>];
};

export function useSnapshot<T>(value: T) {
  const [ref, setValue] = useState(value);
  return [ref, useCallback(() => setValue(value), [value])] as const;
}

export function useDebouncedMemo<T>(
  fn: Fn<void, T>,
  delay: number | [number, number] = 100,
  deps: any[] = []
) {
  const [val, setValue] = useState(fn);

  useEffect(
    useDebouncedCallback(
      () => setValue(fn()),
      isArray(delay) ? delay[0] : delay,
      {
        leading: true,
        trailing: true,
        maxWait: isArray(delay) ? delay[1] : undefined,
      }
    ),
    deps
  );

  return val;
}
export const useSlowMemo = useDebouncedMemo;

export function useShowMore<T>(
  items: T[],
  limit: number,
  showAll: boolean = false
) {
  const [showing, setShowing] = useState(limit);
  const showMoreItems = useCallback(
    () => setShowing(showing + limit * 2),
    [showing, limit]
  );

  return useMemo(
    () =>
      using(
        [items || [], showAll ? items : take(items, showing)],
        (all, visible) => ({
          visible,
          limit,
          moreCount: all.length - visible.length,
          showMore: showMoreItems,
          hasMore: all.length > visible.length,
        })
      ),
    [items, showAll, showing]
  );
}

// Runs an effect once for the first time the condition is true
export const useEffectOnce = (fn: Fn<void, boolean>, deps: any[] = []) => {
  const ran = useRef(false);
  useEffect(() => {
    if (!ran.current && fn()) {
      ran.current = true;
    }
  }, deps);
};

// Runs an effect once for the first time the condition is true
export const useOnce = (key: string) => {
  const substorage = useSubStorage("traction.client.once");

  const once = useCallback(
    (fn: Fn<void, void | Promise<void>>) => {
      if (!substorage.getItem(key)) {
        substorage.setItem(key, true); // Mark as run in localStorage
        fn();
      }
    },
    [key]
  );
  const reset = useCallback(() => {
    substorage.setItem(key, undefined);
  }, [key]);

  return [once, reset] as const;
};

// Uses the default value until value is overriden once, then it will always use the new value
export const useOverridableState = <T>(defaultVal: T): [T, Fn<T, void>] => {
  const [value, setValue] = useState<Maybe<T>>();
  const set = useCallback((v: T) => setValue(v), [setValue]);
  return [value ?? defaultVal, set];
};
