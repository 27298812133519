import { useCallback, useEffect, useMemo, useState } from "react";
import { find, intersection, isString, map, sortBy } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  FetchOptions,
  Ref,
  RelationRef,
  toSearchable,
} from "@api";

import { useSmartSearch } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";
import { useEntityLabels } from "@state/settings";
import { useInstalledEntities } from "@state/packages";
import { useLazyAllTeams } from "@state/teams";
import { useMe } from "@state/persons";

import { omitEmpty } from "@utils/object";
import { withHardHandle } from "@utils/event";
import { extractTeam, toBaseScope, toParentScope } from "@utils/scope";
import { Fn } from "@utils/fn";
import { dependencySort, reverse } from "@utils/array";
import { Maybe } from "@utils/maybe";

import {
  MultiRelationSelect,
  MultiRelationSelectProps,
  RelationSelect,
  RelationSelectProps,
} from "./relation";
import { HStack } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { MenuGroup } from "@ui/menu-group";
import { Button } from "@ui/button";
import { AngleDownIcon, CornerLeftUpAlt, Icon, TeamIcon } from "@ui/icon";
import { useOpenState } from "@ui/dropdown";
import { Divider } from "@ui/divider";
import { RelationLabel } from "@ui/relation-label";
import { Text } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { SplitControl, SplitMenu } from "./comps";

import styles from "./select.module.css";

export type EntitySelectProps = Omit<RelationSelectProps, "options"> & {
  type: EntityType;
  options?: FetchOptions;
};

export type GlobalEntitySelectProps = Omit<EntitySelectProps, "type"> & {
  type?: EntityType;
  scope?: string;
  allowed?: EntityType[] | "*";
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
};

export type MultiEntitySelectProps = Omit<
  MultiRelationSelectProps,
  "options"
> & {
  type: EntityType;
};

export type GlobalMultiEntitySelectProps = Omit<
  MultiEntitySelectProps,
  "type"
> & {
  type?: EntityType;
  scope?: string;
  allowed?: EntityType[] | "*";
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
};

const toOption = (t: Entity) => ({
  id: t.id,
  name: toSearchable(t),
});

export function EntitySelect({
  type,
  className,
  options: opts,
  open: _open,
  setOpen: _setOpen,
  ...props
}: EntitySelectProps) {
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    ...opts,
    toOption,
    empty: open, // Don't search until open
  });

  return (
    <RelationSelect
      {...props}
      className={{
        ...(!isString(className) ? className : {}),
        popover: styles.entityPopover,
      }}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function EntityMultiSelect({ type, ...props }: MultiEntitySelectProps) {
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    toOption,
    archived: false,
    empty: props.open,
  });

  return (
    <MultiRelationSelect
      {...props}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function GlobalEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  showOtherTeams = true,
  allowed,
  className,
  ...props
}: GlobalEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, setOpen] = useOpenState(_open, _setOpen);

  const [filter, setFilter] = useState<DatabaseID>(() => ({
    type: props.type || "task",
    scope: props.scope || "",
  }));

  const { onSearch, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    useMemo(
      () => ({ templates, empty: open, archived: false, toOption }),
      [open, templates]
    )
  );

  const scopeAllowed = useAllowedTypes(
    open ? filter.scope : undefined,
    allowed
  );
  const isSwitchingTeam = useMemo(
    () =>
      filter.type === "team" &&
      !(scopeAllowed?.length === 1 && scopeAllowed[0] === "team"),
    [filter.type, props.type, scopeAllowed]
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const handleValueChanged = useCallback(
    (value: Maybe<Ref>) => {
      if (!value) {
        return props.onChange?.(undefined);
      }

      if (isSwitchingTeam) {
        handleScopeChanged({
          scope: value.id,
          type: find(scopeAllowed, (t) => t !== "team") || props.type || "task",
        });
      } else {
        props.onChange?.(value);
      }
    },
    [handleScopeChanged, isSwitchingTeam, scopeAllowed]
  );

  const Menu = useMemo(
    () =>
      open &&
      filter.type !== "team" &&
      SplitMenu<RelationRef, false>(
        undefined,
        <SideMenu
          {...props}
          showTeam={props.showTeam ?? true}
          showOtherTeams={showOtherTeams}
          open={open}
          setOpen={setOpen}
          allowed={scopeAllowed}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [open, filter]
  );

  // When props input changes
  useEffect(() => {
    handleScopeChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <RelationSelect
      {...props}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      closeOnBlur={false}
      closeOnSelect={isSwitchingTeam ? false : props.closeOnSelect}
      onSearch={onSearch}
      onChange={handleValueChanged}
      className={{
        ...(!isString(className) ? className : { dropdown: className }),
        popover: styles.splitPopover,
      }}
      overrides={{
        ...props.overrides,
        ...(Menu ? { Menu } : {}),
      }}
    />
  );
}

export function GlobalMultiEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  closeOnTeam,
  showOtherTeams = true,
  ...props
}: GlobalMultiEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, setOpen] = useOpenState(_open, _setOpen);

  const [filter, setFilter] = useState<DatabaseID>(() => ({
    type: props.type || "task",
    scope: props.scope || "",
  }));

  const { onSearch, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    { templates, empty: open, archived: false, toOption }
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const Control = useMemo(
    () =>
      open &&
      showOtherTeams &&
      SplitControl<RelationRef, true>(
        <ScopeMenu filter={filter} onFilterChanged={handleScopeChanged} />
      ),
    [open, filter]
  );

  const Menu = useMemo(
    () =>
      open &&
      SplitMenu<RelationRef, true>(
        undefined,
        <SideMenu
          allowed={props.allowed}
          showTeam={props.showTeam ?? true}
          showOtherTeams={false}
          open={open}
          setOpen={setOpen}
          closeOnTeam={closeOnTeam}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [filter, open, props.showTeam]
  );

  // When props input changes
  useEffect(() => {
    handleScopeChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <MultiRelationSelect
      {...props}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      onSearch={onSearch}
      className={{ popover: styles.splitPopover }}
      overrides={{
        ...props.overrides,
        ...(Menu ? { Menu } : {}),
        ...(Control ? { Control } : {}),
      }}
    />
  );
}

type SplitMenuProps = Pick<
  GlobalEntitySelectProps,
  | "allowed"
  | "open"
  | "setOpen"
  | "onChange"
  | "scope"
  | "closeOnTeam"
  | "showTeam"
  | "showOtherTeams"
> & {
  filter: DatabaseID;
  onFilterChanged: Fn<Partial<DatabaseID>, void>;
};

function ScopeMenu({ filter, onFilterChanged }: SplitMenuProps) {
  const allTeams = useLazyAllTeams();
  const [selecting, setSelecting] = useState(false);
  const selected = useMemo(
    () => find(allTeams, (t) => filter.scope?.includes(t.id)),
    [allTeams]
  );
  const teams = useMemo(
    () => dependencySort(allTeams, (t) => t.parent?.id),
    [allTeams]
  );

  return (
    <>
      <Button
        size="small"
        iconRight={AngleDownIcon}
        onClick={withHardHandle(() => setSelecting(!selecting))}
      >
        {!!selected && (
          <HStack gap={4}>
            <Icon size="small" icon={<TeamIcon team={selected} />} />
            {selected.name}
          </HStack>
        )}

        {!selected && "Workspace"}
      </Button>

      {selecting && (
        <div className={styles.takeover} onClick={withHardHandle(() => {})}>
          {map(teams, (team) => (
            <MenuItem
              key={team.id}
              icon={<TeamIcon team={team} />}
              onClick={withHardHandle(() => {
                onFilterChanged?.({ scope: team.id });
                setSelecting(false);
              })}
            >
              {team.name}
            </MenuItem>
          ))}
        </div>
      )}
    </>
  );
}

export function SideMenu({
  showTeam,
  showOtherTeams,
  allowed,
  scope,
  filter,
  onChange,
  closeOnTeam,
  onFilterChanged,
  setOpen,
}: SplitMenuProps) {
  const wID = useActiveWorkspaceId();

  const toLabel = useEntityLabels(scope);

  const handleSelected = useCallback(
    (type: EntityType) => onFilterChanged({ type }),
    [onFilterChanged, filter]
  );

  const scopeTeam = useMemo(() => extractTeam(filter.scope), [filter.scope]);

  if (!allowed?.length) {
    return <></>;
  }

  return (
    <MenuGroup inset={false}>
      {showOtherTeams && (
        <>
          <Tooltip text="Change team">
            <MenuItem
              icon={CornerLeftUpAlt}
              onClick={withHardHandle(() =>
                onFilterChanged?.({ type: "team", scope: wID })
              )}
            >
              <Text subtle>Teams</Text>
            </MenuItem>
          </Tooltip>

          <Divider className={styles.sideDivider} />
        </>
      )}

      {showTeam && !!scopeTeam && onChange && (
        <Tooltip text="Move directly under team">
          <MenuItem
            onClick={withHardHandle(() => {
              onChange?.({ id: scopeTeam });
              closeOnTeam !== false && setOpen?.(false);
            })}
          >
            <RelationLabel relation={{ id: scopeTeam }} />
          </MenuItem>
        </Tooltip>
      )}

      {map(allowed, (t: EntityType) => (
        <MenuItem
          key={t}
          selected={t === filter.type}
          onClick={withHardHandle(() => handleSelected(t))}
        >
          {toLabel(t, { plural: true, case: "title" })}
        </MenuItem>
      ))}
    </MenuGroup>
  );
}

function useAllowedTypes(
  scope: Maybe<string>,
  whitelist: Maybe<EntityType[] | "*">
) {
  const me = useMe();
  const allEntities = useInstalledEntities(
    scope ? toBaseScope(toParentScope(scope)) || me.id : undefined
  );

  return useMemo(() => {
    if (!scope) {
      return;
    }
    const all = [...reverse(allEntities), "person"];
    return sortBy(
      (whitelist?.[0] === "*" || !whitelist?.length
        ? all
        : intersection(all, whitelist)) as EntityType[],
      (t) =>
        ["task", "content", "outcome", "request"]?.includes(t)
          ? `1${t}`
          : `0${t}`
    );
  }, [allEntities, whitelist]);
}
