import { atomFamily, selectorFamily } from "recoil";

import { ID, Pipeline } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type PipelineStoreState = StoreState<Pipeline>;

export const WorkspacePipelineStoreAtom = atomFamily<PipelineStoreState, ID>({
  key: "WorkspacePipelineStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "pipeline")],
});

export const PipelineStoreAtom = WorkspaceWrappedAtom(
  "PipelineStoreAtom",
  WorkspacePipelineStoreAtom
);

export const PipelineAtom = selectorFamily({
  key: "PipelineAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(PipelineStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(PipelineStoreAtom, mergeItem<Pipeline>(newValue));
      }
    },
});
