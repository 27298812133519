import {
  DatabaseID,
  EntityType,
  HasTemplate,
  ID,
  isTemplateEntity,
  Schedule,
} from "@api";
import { filter, find, first, groupBy, keys, map, orderBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useLazyTeam } from "@state/teams";
import { useLazyAllTemplates } from "@state/templates";
import { useLazyWorkspace } from "@state/workspace";
import { useEntityLabels } from "@state/settings";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";
import { useInstalledEntities } from "@state/packages";
import { useCreateEntity, useUpdateEntity } from "@state/generic";
import { useLazyPropertyDef } from "@state/databases";
import { needsScheduling, useCreateNextForSchedule } from "@state/schedule";

import { Maybe, maybeMap, when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";
import { fallbackPropertyValue, inflateProperty } from "@utils/property-refs";
import { toScope } from "@utils/scope";
import { usePushTo } from "@utils/navigation";
import { sentenceCase } from "@utils/string";
import { useQueryParams, useStickyState } from "@utils/hooks";
import { usePageSelection } from "@utils/selectable";
import { toggle } from "@utils/logic";
import { maybeTypeFromId } from "@utils/id";
import { plural } from "@utils/string";

import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered } from "@ui/container";
import { Divider } from "@ui/divider";
import { render, useEngine } from "@ui/engine";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { NotionSelect } from "@ui/select/notion";
import { SlackSelect } from "@ui/select/slack";
import { Heading, Text, TextLarge } from "@ui/text";
import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Button, Props as ButtonProps } from "@ui/button";
import {
  Building,
  Eye,
  EyeSlash,
  Icon,
  Play,
  PlusIcon,
  StatusIcon,
} from "@ui/icon";
import { EmptyMenuItem, MenuItem } from "@ui/menu-item";
import templateEngine from "@ui/engine/template";
import { FormCreateDialog } from "@ui/engine/form/create-dialog";
import SettingsSchema from "./settings-schemas";
import {
  PersonMultiSelect,
  PersonSelect,
  Select,
  TextSubtextOption,
} from "@ui/select";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import ProgressBar from "@ui/progress-bar";
import { Dialog } from "@ui/dialog";
import { TabBar } from "@ui/tab-bar";

import { AddWorkButton } from "./add-work-dialog";

import styles from "./settings-team.module.css";

interface Props {
  teamId: ID;
}

export const TEAM_VISIBILITIES = [
  {
    id: "open",
    name: "Open",
    subtext: "Team and work visible to all members in workspace.",
    icon: Eye,
  },
  {
    id: "closed",
    name: "Closed",
    subtext: "Team (not work) visible to all members in workspace.",
    icon: Building,
  },
  {
    id: "private",
    name: "Private",
    subtext: "Visible only to members.",
    icon: EyeSlash,
  },
];

export default function SettingsTeam({ teamId }: Props) {
  const entity = useLazyTeam(teamId);
  const workspace = useLazyWorkspace();
  const mutate = useUpdateEntity(teamId);

  if (!(entity && workspace)) {
    return <></>;
  }

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Data</Heading>
        <Text subtle>Where your team data lives n stuff...</Text>
      </VStack>

      <Divider direction="horizontal" />

      <SpaceBetween>
        <Field label="Team name">
          <TextInput
            value={entity?.name || ""}
            onChange={(name) =>
              entity &&
              name &&
              mutate([
                toMutation(entity, { field: "name", type: "text" }, name),
              ])
            }
            updateOn="blur"
            placeholder="Update team name..."
          />
        </Field>
        <Field label="Visibility">
          <Select
            options={TEAM_VISIBILITIES}
            value={find(TEAM_VISIBILITIES, (o) => o.id === entity.visibility)}
            overrides={useMemo(() => ({ Option: TextSubtextOption }), [])}
            className={{ trigger: styles.control }}
            onChange={(v) =>
              mutate([
                toMutation(
                  entity,
                  { field: "visibility", type: "text" },
                  v?.id
                ),
              ])
            }
          />
        </Field>
      </SpaceBetween>

      <SpaceBetween>
        <Field label="Team Owner">
          <PersonSelect
            className={styles.control}
            value={entity.owner}
            portal={true}
            onChange={(p) =>
              p &&
              mutate([
                toMutation(entity, { field: "owner", type: "relation" }, p),
              ])
            }
            placeholder="Select owner..."
          />
        </Field>
        <Field label="Team Members" fit="container">
          <PersonMultiSelect
            className={{ trigger: styles.control }}
            value={entity.people}
            portal={true}
            onChange={(p) =>
              p &&
              mutate([
                toMutation(entity, { field: "people", type: "relations" }, p),
              ])
            }
            placeholder="Select owner..."
          />
        </Field>
      </SpaceBetween>

      <Divider direction="horizontal" />

      <VStack gap={20} fit="container">
        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Team Notion Homepage</TextLarge>
              <Text subtle>Location for Team Docs.</Text>
            </VStack>
          </FillSpace>

          <NotionSelect
            value={(entity?.notionId as Maybe<string>) || ""}
            mode="pages"
            onChange={(t) =>
              entity &&
              mutate([
                toMutation(entity, { field: "notionId", type: "text" }, t),
              ])
            }
          />
        </SpaceBetween>

        <Divider />

        <SpaceBetween fit="container">
          <FillSpace>
            <VStack gap={0}>
              <TextLarge bold>Team Slack Channel</TextLarge>
              <Text subtle>Create new threads in this Slack Channel.</Text>
            </VStack>
          </FillSpace>

          <SlackSelect
            mode="channel"
            channel={
              fallbackPropertyValue([entity, workspace], {
                field: "settings.channel",
                type: "text",
              })?.text
            }
            placeholder="Select a channel..."
            onChange={(id) =>
              mutate([
                toMutation(
                  entity,
                  { field: "settings.channel", type: "text" },
                  id
                ),
              ])
            }
          />
        </SpaceBetween>
      </VStack>
    </Centered>
  );
}

export function SettingsTeamProperties({ teamId }: Props) {
  const entity = useLazyTeam(teamId);
  const workspace = useLazyWorkspace();
  const params = useQueryParams();

  if (!(entity && workspace)) {
    return <></>;
  }

  return (
    <Centered gap={20} stack="vertical">
      <VStack gap={0}>
        <Heading bold>Fields</Heading>
        <Text subtle>Customize the fields available on your team's work.</Text>
      </VStack>

      {entity && (
        <SettingsSchema
          parent={entity}
          type={params.type as Maybe<EntityType>}
          editing={params.field as Maybe<string>}
        />
      )}

      <Text subtle>
        More fields are inherited from your workspace. You can edit them in{" "}
        <Link to="/settings/properties">Workspace settings</Link>.
      </Text>
    </Centered>
  );
}

const CreateTemplateActionItem = ({ source }: { source: DatabaseID }) => {
  const pushTo = usePushTo();
  const create = useCreateEntity(source.type, source.scope);
  const toLabel = useEntityLabels(source.scope);
  return (
    <ActionItem
      icon={PlusIcon}
      onClick={() =>
        pushTo(
          create([{ field: "template", type: "text", value: { text: "root" } }])
        )
      }
    >
      {sentenceCase(toLabel(source.type))} template
    </ActionItem>
  );
};

const TEMPLATE_TABS = [
  { id: "items", title: "Base Templates" },
  { id: "forms", title: "Forms" },
  // { id: "workflows", title: "Workflows" },
];

export function SettingsTeamTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const [active, setActive] = useStickyState<string>(
    "items",
    "team-templates-tab"
  );
  const [creating, setCreating] = useState(false);
  const installedTypes = useInstalledEntities(teamId);
  const createables = useMemo(
    () =>
      maybeMap(installedTypes, (t) =>
        isTemplateEntity(t) ? undefined : { type: t, scope: toScope(teamId) }
      ),
    [installedTypes]
  );

  return (
    <Centered gap={20} stack="vertical">
      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>Templates</Heading>
          <Text subtle>
            The templates here are available for use anywhere within this team.
          </Text>
        </VStack>
      </SpaceBetween>

      <SpaceBetween>
        <TabBar
          active={active}
          onActiveChanged={setActive}
          options={TEMPLATE_TABS}
        />

        {active === "items" && (
          <ActionMenu
            actions={
              <>
                {map(createables, (source) => (
                  <CreateTemplateActionItem key={source.type} source={source} />
                ))}
              </>
            }
          >
            <Button variant="primary">New template</Button>
          </ActionMenu>
        )}

        {active === "forms" && (
          <Button variant="primary" onClick={() => setCreating(true)}>
            New form
          </Button>
        )}
      </SpaceBetween>

      {creating && (
        <FormCreateDialog
          defaults={{ location: teamId }}
          onCancel={() => setCreating(false)}
          onSaved={(r) => {
            setCreating(false);
            pushTo([r, "/builder"]);
          }}
        />
      )}

      {active === "items" && <BaseTemplates teamId={teamId} />}
      {active === "forms" && <FormTemplates teamId={teamId} />}
    </Centered>
  );
}

function FormTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const forms = useLazyAllTemplates(teamId, ["form"]);
  const byType = useMemo(
    () => groupBy(forms.form, (f) => f.entity),
    [forms.form]
  );
  const toLabel = useEntityLabels(teamId);
  const [selection, setSelection] = usePageSelection();

  return (
    <VStack gap={10}>
      {map(
        byType,
        (vals, type: EntityType) =>
          !!vals?.length &&
          !isTemplateEntity(type) && (
            <CollapsibleSection
              key={type}
              title={`Forms creating ${toLabel(type, { plural: true })}`}
              padded={false}
            >
              <VStack gap={0} fit="container">
                {map(vals as HasTemplate[], (item) =>
                  render(templateEngine.asListItem, {
                    key: item.id,
                    item: item,
                    selection: selection,
                    setSelection: setSelection,
                    onOpen: (f) => pushTo([f, "/builder"]),
                  })
                )}
              </VStack>
            </CollapsibleSection>
          )
      )}
    </VStack>
  );
}

function BaseTemplates({ teamId }: Props) {
  const pushTo = usePushTo();
  const templates = useLazyAllTemplates(teamId);
  const toLabel = useEntityLabels(teamId);
  const [selection, setSelection] = usePageSelection();

  return (
    <VStack gap={10}>
      {map(
        templates,
        (vals, type: EntityType) =>
          !!vals?.length &&
          !isTemplateEntity(type) && (
            <CollapsibleSection
              key={type}
              title={plural(toLabel(type))}
              padded={false}
            >
              <VStack gap={0} fit="container">
                {map(vals as HasTemplate[], (item) =>
                  render(templateEngine.asListItem, {
                    key: item.id,
                    item: item,
                    selection: selection,
                    setSelection: setSelection,
                    onOpen: pushTo,
                  })
                )}
              </VStack>
            </CollapsibleSection>
          )
      )}
    </VStack>
  );
}

export function SettingsTeamSchedules({ teamId }: Props) {
  const pushTo = usePushTo();
  const schedules = useLazyItemsForView(
    toTemplateViewId("team-schedule", { parent: teamId })
  );
  const toLabel = useEntityLabels(teamId);
  const engine = useEngine("schedule");
  const [selection, setSelection] = usePageSelection();
  const grouped = useMemo(
    () =>
      groupBy(
        schedules.items?.sorted as Schedule[],
        (s) =>
          s.entity ||
          when((s.useTemplate || first(s.instances))?.id, maybeTypeFromId)
      ),
    [schedules.items?.sorted]
  );
  const groups = useMemo(() => orderBy(keys(grouped)), [grouped]);

  return (
    <Centered gap={20} stack="vertical">
      <SpaceBetween>
        <VStack gap={0}>
          <Heading bold>Recurring Schedules</Heading>
          <Text subtle>Your team's recurring work schedules.</Text>
        </VStack>

        <HStack>
          <ScheduleAllButton
            schedules={(schedules.items?.sorted || []) as Schedule[]}
            teamId={teamId}
            size="small"
          />

          <AddWorkButton
            defaults={{
              location: teamId,
              source: { type: "schedule", scope: teamId },
            }}
            onSaved={pushTo}
          >
            New Schedule
          </AddWorkButton>
        </HStack>
      </SpaceBetween>

      {map(groups, (type: EntityType) => (
        <CollapsibleSection key={type} title={`${toLabel(type)} schedules`}>
          <VStack gap={0} fit="container">
            {map(grouped[type], (schedule: Schedule) =>
              render(engine?.asListItem, {
                key: schedule.id,
                item: schedule,
                onOpen: pushTo,
                selection,
                setSelection,
              })
            )}

            {!(schedules?.items?.sorted || schedules?.items?.all)?.length && (
              <EmptyMenuItem>
                No schedules created within this team.
              </EmptyMenuItem>
            )}
          </VStack>
        </CollapsibleSection>
      ))}
    </Centered>
  );
}

// TODO: Remove after queuing is implemented
function ScheduleAllButton({
  schedules,
  teamId,
  ...buttonProps
}: {
  teamId: ID;
  schedules: Schedule[];
} & ButtonProps) {
  const [creating, setCreating] = useState(false);
  const status = useLazyPropertyDef(
    { type: "schedule", scope: teamId },
    { field: "status", type: "status" }
  );
  const runnable = useMemo(
    () =>
      filter(
        schedules,
        (s) =>
          inflateProperty(s, status)?.status?.group === "in-progress" &&
          needsScheduling(s)
      ),
    [schedules]
  );

  return (
    <>
      {creating && (
        <Dialog
          onDismiss={() => setCreating(false)}
          title={"Running Schedules"}
          description={`Creating ${runnable.length} schedules.`}
          actions={
            <HStack>
              <Button onClick={() => setCreating(false)}>Done</Button>
            </HStack>
          }
        >
          <Menu>
            <MenuGroup>
              {map(runnable, (s) => (
                <ScheduleRunnerMenuItem key={s.id} schedule={s} />
              ))}
              {!runnable.length && (
                <EmptyMenuItem>All schedules created.</EmptyMenuItem>
              )}
            </MenuGroup>
          </Menu>
        </Dialog>
      )}

      {!!runnable.length && (
        <Button
          icon={Play}
          loading={creating}
          onClick={() => setCreating(toggle)}
          variant="primary"
          {...buttonProps}
        >
          Run scheduler ({runnable.length})
        </Button>
      )}
    </>
  );
}

function ScheduleRunnerMenuItem({ schedule }: { schedule: Schedule }) {
  const interval = useRef<NodeJS.Timeout>();
  const [complete, setComplete] = useState(0);
  const { create, next, loading } = useCreateNextForSchedule(schedule.id);

  useEffect(() => {
    interval.current = setInterval(() => {
      setComplete((prev) => Math.min(prev + 1, 98));
    }, 100);

    return () => clearInterval(interval.current);
  }, []);

  useEffect(() => {
    // Keep creating until next is undefined
    if (next && !loading) {
      create();
    } else if (!next) {
      setComplete(100);
    }
  }, [next, loading]);

  return (
    <MenuItem>
      <HStack>
        <Text>{schedule.name}</Text>

        <FillSpace>
          {complete !== 100 && <ProgressBar percent={complete} />}
        </FillSpace>

        {complete === 100 && (
          <Icon icon={<StatusIcon status={{ group: "done" }} />} />
        )}
      </HStack>
    </MenuItem>
  );
}
