import { first, flatMap, without } from "lodash";
import { useState, useCallback, useMemo, useEffect } from "react";

import { Calendar, EntityType, Ref } from "@api";

import { useLazyProperties } from "@state/databases";
import { useCreateFromObject } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import { Fn } from "@utils/fn";
import { getSetting, isAnyRelation, setSetting } from "@utils/property-refs";
import { ensureMany } from "@utils/array";

import { Button } from "@ui/button";
import { Field, TextInput } from "@ui/input";
import { Text } from "@ui/text";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { DialogSplit } from "@ui/dialog-split";
import { Container } from "@ui/container";
import { EducationTip } from "@ui/education-tip";
import { showError } from "@ui/notifications";
import { MagicEmojiSelect } from "@ui/select/emoji";

import { EntityTypeSelect } from "@ui/select/entity-type";

interface Props {
  defaults?: Partial<Omit<Calendar, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const CalendarCreateDialog = ({
  onCancel,
  onSaved,
  defaults,
}: Props) => {
  const wID = useActiveWorkspaceId();
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const create = useCreateFromObject("calendar", scope);
  const [calendar, setCalendar] = useState<Partial<Calendar>>({
    icon: "📋",
    ...defaults,
  });
  const relations = useLazyProperties(calendar?.source);
  const allowedChildren = useMemo(
    () =>
      without(
        flatMap(relations, (p) =>
          isAnyRelation(p) &&
          p.options?.references !== "view" &&
          p.options?.hierarchy === "child"
            ? ensureMany(p?.options?.references)
            : []
        ),
        "resource",
        "note",
        "action"
      ),
    [relations]
  );

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([calendar]);

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [create, calendar, onSaved]);

  useEffect(() => {
    const defaultt = first(allowedChildren);

    if (!defaultt) {
      return;
    }

    setCalendar((r) => ({
      ...r,
      settings: setSetting(r.settings, "child_type", defaultt),
    }));
  }, [allowedChildren]);

  return (
    <DialogSplit
      title={"New calendar"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>
            Calendars are about organising any kind of work, whether be big or
            small.
          </Text>
          <EducationTip relevantTo={["calendar"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create calendar
          </Button>
        </>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            {calendar.icon && (
              <Field layout="horizontal">
                <MagicEmojiSelect
                  key={calendar.id || "new-calendar"}
                  entity={calendar as Calendar}
                  size="xlarge"
                  emoji={calendar.icon}
                  onChange={(icon) => setCalendar((r) => ({ ...r, icon }))}
                />
              </Field>
            )}

            <Field label="Name">
              <TextInput
                value={calendar.name || ""}
                onChange={(t) => setCalendar((b) => ({ ...b, name: t }))}
                updateOn="change"
                autoFocus={true}
                placeholder="Enter calendar name..."
              />
            </Field>

            <Field label="Organising">
              <EntityTypeSelect
                value={getSetting<EntityType>(calendar.settings, "child_type")}
                allowed={allowedChildren}
                scope={scope}
                portal
                onChange={(entity) =>
                  setCalendar((r) => ({
                    ...r,
                    settings: setSetting(r.settings, "child_type", entity),
                  }))
                }
              />
            </Field>
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
