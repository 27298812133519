import { map } from "lodash";
import { useMemo } from "react";

import { Meeting } from "@api";

import { isStartingSoon } from "@state/meetings";
import { useLazyPropertyValue, useVisiblePropertyRefs } from "@state/databases";

import { cx } from "@utils/class-names";
import { formatDay } from "@utils/date";
import { withHandle } from "@utils/event";

import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text } from "@ui/text";
import { StatusTag, Tag } from "@ui/tag";
import { PropertyValue } from "@ui/property-value";

import { ListItemOpts, ViewEngine } from "../types";

import { MeetingDivider, NowIndicator, TimeStack } from "./comps";
import { MeetingCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";
import { usePointDate } from "@utils/date-fp";

export { MeetingCreateDialog } from "./create-dialog";
export { MeetingHostPane } from "./host-pane";
export { EndMeetingDialog } from "./end-meeting-dialog";
export { MeetingPane, RelatedMeetingsPane, MeetingLinksPane } from "./pane";

export const MeetingEngine: ViewEngine<Meeting> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          className={styles.relative}
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
        >
          <SpaceBetween>
            <HStack align="stretch">
              <VStack gap={0} fit="content">
                <TimeStack entity={item} />
                <NowIndicator start={item.start} end={item.end} />
              </VStack>
              <MeetingDivider entity={item} />
              <HStack gap={4}>
                <Text>{item.name}</Text>
                {item.start && (
                  <Text subtle>{usePointDate(item.start, formatDay)}</Text>
                )}
              </HStack>
            </HStack>
            <MeetingRunningIndicator meeting={item} />
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <SpaceBetween direction="horizontal" gap={6} align="stretch">
            <MeetingDivider className={styles.fill} entity={item} />

            <VStack className={styles.bar} gap={0}>
              <SpaceBetween>
                <Ellipsis>
                  <HStack gap={4}>
                    <Text>{item.name || "Meeting"}</Text>
                    {item.start && (
                      <Text subtle>{usePointDate(item.start, formatDay)}</Text>
                    )}
                  </HStack>
                </Ellipsis>
                <MeetingRunningIndicator meeting={item} />
              </SpaceBetween>

              <SpaceBetween>
                <HStack gap={4}>
                  <TimeStack entity={item} separator={"-"} />
                </HStack>
              </SpaceBetween>
            </VStack>
          </SpaceBetween>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props: ListItemOpts<Meeting>) {
    const { item, showProps, variant, onChange } = props;
    const { visible } = useVisiblePropertyRefs(item, showProps, {
      blacklist: ["name", "status", "start", "end"],
      hideEmpty: props.hideEmpty,
    });
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem
          {...props}
          className={cx(styles.relative, props.className)}
        >
          <SpaceBetween className={styles.upper}>
            <HStack align="stretch">
              <VStack gap={0} fit="content">
                <TimeStack entity={item} />
                <NowIndicator start={item.start} end={item.end} />
              </VStack>
              <MeetingDivider entity={item} />
              <HStack gap={4}>
                <Text>{item.name}</Text>
                {item.start && (
                  <Text subtle>{usePointDate(item.start, formatDay)}</Text>
                )}
              </HStack>
            </HStack>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              {map(visible, (val) => (
                <PropertyValue
                  key={val.field}
                  valueRef={val}
                  source={item.source}
                  variant={variant || "unlabelled"}
                  onChange={(c) =>
                    onChange?.({
                      field: val.field,
                      type: val.type,
                      value: c,
                      prev: val.value,
                    })
                  }
                />
              ))}
              <MeetingRunningIndicator meeting={item} />
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
  asCreateDialog: MeetingCreateDialog,
};

const MeetingRunningIndicator = ({ meeting }: { meeting: Meeting }) => {
  const startingSoon = useMemo(() => isStartingSoon(meeting), [meeting]);
  const {
    value: { status },
  } = useLazyPropertyValue(meeting, {
    field: "status",
    type: "status",
  });

  return (
    <>
      {status?.group === "in-progress" ? (
        <StatusTag size="small" showIcon={true} status={status} />
      ) : startingSoon ? (
        <Tag size="small" color="blue_3">
          Starting soon
        </Tag>
      ) : (
        <></>
      )}
    </>
  );
};
