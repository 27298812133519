import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { isString, map } from "lodash";
import {
  SelectComponentsConfig,
  GroupBase,
  OnChangeValue,
  SelectInstance,
  InputActionMeta,
  OptionProps,
} from "react-select";
import Select from "react-select/creatable";

import { Color } from "@api";

import { Maybe, when } from "@utils/maybe";
import { switchEnum } from "@utils/logic";
import { Fn } from "@utils/fn";
import { cx } from "@utils/class-names";
import { useShortcut } from "@utils/event";
import { asLocal, cid } from "@utils/id";

import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { Button } from "@ui/button";
import { CheckIcon, Icon } from "@ui/icon";
import { Dropdown, Props as DropdownProps, useOpenState } from "@ui/dropdown";
import { Tag } from "@ui/tag";
import { HStack } from "@ui/flex";
import { PlaceholderText } from "@ui/text";

import {
  ClearIndicator,
  LoadingIndicator,
  MenuOverride,
  NoOptionsMessage,
  Option,
} from "./single-select";

import styles from "./select.module.css";

export type MultiOption = Option & {
  color?: Color | "default";
  indent?: number;
};

const None = () => <></>;

export type MultiProps<T extends MultiOption> = {
  value: Maybe<T[]>;
  options: T[];
  toIcon?: Fn<T, MenuItemProps["icon"]>;
  // TODO: Change this to be one or the other
  className?:
    | string
    | {
        select?: string;
        popover?: string;
        dropdown?: string;
        trigger?: string;
      };
  searchable?: boolean;
  createable?: boolean;
  loading?: boolean;
  clearable?: boolean;
  placeholder?: string;
  portal?: boolean;
  position?: DropdownProps["position"];
  closeOnBlur?: boolean;
  closeOnSelect?: boolean;
  onSearch?: Fn<string, void>;
  onChange?: Fn<Maybe<T[]>, void>;
  onBlur?: Fn<void, void>;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  overrides?: SelectComponentsConfig<T, true, GroupBase<T>>;
  // Either can pass in a default or control it externally
  defaultOpen?: boolean;
  open?: boolean;
  setOpen?: Fn<boolean, void>;
};

const MultiOption =
  <T extends MultiOption>({ toIcon }: Pick<MultiProps<T>, "toIcon">) =>
  ({
    innerRef,
    innerProps,
    isFocused,
    isSelected,
    children,
    data,
  }: OptionProps<T, true>) => {
    return (
      <div ref={innerRef} {...innerProps}>
        <MenuItem
          className={cx(styles.menuItem, isFocused && styles.focused)}
          indent={(data as Maybe<{ indent: number }>)?.indent || 0}
          icon={<CheckIcon checked={isSelected} />}
        >
          <HStack gap={4}>
            {toIcon ? when(toIcon(data), (i) => <Icon icon={i} />) : <></>}

            <Tag color={data.color || "default"}>{children}</Tag>
          </HStack>
        </MenuItem>
      </div>
    );
  };

export const MultiSelect = <T extends MultiOption>({
  value,
  onChange,
  options,
  className,
  portal = true,
  searchable = true,
  closeOnBlur = true,
  closeOnSelect = false,
  clearable = true,
  createable = false,
  position,
  loading,
  onSearch,
  placeholder,
  children,
  header,
  footer,
  toIcon,
  onBlur,
  overrides,
  ...props
}: MultiProps<T>) => {
  const select = useRef<SelectInstance<T, true>>(null);
  const [filtering, setFiltering] = useState(false);
  const [_open, _setOpen] = useOpenState(props.defaultOpen);
  const open = props.open ?? _open;
  const setOpen = props.setOpen ?? _setOpen;

  // Close when escaping from empty filter input
  useShortcut(
    "Escape",
    [
      (e) => !e.defaultPrevented || !filtering,
      () => {
        setOpen(false);
        onBlur?.();
      },
    ],
    [filtering]
  );
  // Track filtering state to know above escaping rule
  useEffect(() => {
    const listener = (e: Event) => {
      setFiltering(!!(e.target as HTMLInputElement).value);
    };
    select.current?.inputRef?.addEventListener(
      "keydown",
      listener as EventListener
    );
    return () =>
      select.current?.inputRef?.removeEventListener("keydown", listener);
  }, [select.current?.inputRef]);

  const handleChanged = useCallback(
    (n: OnChangeValue<T, true>) => {
      onChange?.((n as Maybe<T[]>) || undefined);
      if (closeOnSelect) {
        setOpen(false);
      }
    },
    [closeOnSelect, setOpen, onChange]
  );
  const handleBlur = useCallback(() => {
    if (closeOnBlur) {
      // This allows clicking links inside of the dropdown before it closes...
      setTimeout(() => {
        onBlur?.();
        setOpen(false);
      }, 400);
    } else {
      onBlur?.();
    }
  }, [onBlur, setOpen]);

  const handleInputChange = useCallback(
    (search: string, o: InputActionMeta) => {
      if (!onSearch) {
        return;
      }

      switchEnum(o.action, {
        "input-change": () => onSearch(search),
        "set-value": () => onSearch(search),
        else: () => () => onSearch(""),
      });
    },
    [onSearch]
  );

  useLayoutEffect(() => {
    if (open) {
      select.current?.inputRef?.focus();
    }
  }, [select.current, open]);

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      portal={portal}
      position={position}
      closeOnEscape={false}
      className={!isString(className) ? className : undefined}
      trigger={
        children ?? (
          <Button
            className={cx(
              styles.button,
              open && styles.open,
              isString(className) ? className : className?.trigger
            )}
            subtle
            size="small"
          >
            <HStack gap={3} className={cx(styles.value)}>
              {map(value, (data) => (
                <Tag key={data.id} color={data.color || "default"}>
                  {data.name}
                </Tag>
              ))}
            </HStack>
            {!value?.length && (
              <PlaceholderText>{placeholder || "Select..."}</PlaceholderText>
            )}
          </Button>
        )
      }
    >
      <>
        {header}
        <Select
          ref={select}
          className={cx(
            styles.select,
            !searchable && styles.noSearch,
            !isString(className) && className?.select
          )}
          isSearchable={true}
          isValidNewOption={(v) => !!v?.trim() && createable}
          getNewOptionData={(v) => ({ id: asLocal(cid(4)), name: v } as T)}
          classNamePrefix="select"
          getOptionValue={(o) => o.id || ""}
          getOptionLabel={(o) => o.name || ""}
          menuIsOpen={open}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          autoFocus={true}
          closeMenuOnSelect={false}
          escapeClearsValue={false}
          isClearable={clearable}
          isLoading={loading}
          isMulti={true}
          defaultValue={value}
          placeholder={placeholder || "Filter for options..."}
          options={options}
          onInputChange={handleInputChange}
          onChange={handleChanged}
          onBlur={handleBlur}
          components={useMemo(
            () => ({
              Option: MultiOption({ toIcon }),
              LoadingIndicator: LoadingIndicator,
              NoOptionsMessage: NoOptionsMessage,
              ClearIndicator: ClearIndicator,
              Menu: MenuOverride,
              DropdownIndicator: None,
              IndicatorSeparator: None,
              ...overrides,
            }),
            [toIcon, overrides]
          )}
        />
      </>
      {footer}
    </Dropdown>
  );
};
