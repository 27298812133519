import { ReactNode, useMemo } from "react";
import { isArray, isEmpty, map, isString } from "lodash";

import {
  DatabaseID,
  Entity,
  PropertyFormat,
  PropertyRef,
  PropertyValueRef,
} from "@api";

import { useLazyPropertyValues } from "@state/databases";

import { Fn } from "@utils/fn";
import { isDefined, Maybe, when } from "@utils/maybe";
import {
  toLabel,
  toPlaceholder,
  inflateValue,
  formatLocation,
  toPropertyValueRef,
} from "@utils/property-refs";
import { cx } from "@utils/class-names";
import { ensureArray, justOne } from "@utils/array";
import { withHardHandle } from "@utils/event";
import { useGoTo } from "@utils/navigation";

import { Tag, Tags } from "@ui/tag";
import { Button } from "@ui/button";
import { StatusIcon } from "@ui/status-button";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { SpaceBetween } from "@ui/flex";
import { ArrowUpRight, Icon } from "@ui/icon";
import { Container } from "@ui/container";
import { Label, Props as LabelProps } from "@ui/label";
import { LocationLabel } from "@ui/location-button";

import styles from "./property-value.module.css";

export type PropertyTypeVariant = "icon-only" | "unlabelled" | "labelled";

export type Props<E extends Entity> = {
  valueRef: PropertyValueRef<E>;
  format?: PropertyFormat;
  source: DatabaseID;
  children?: ReactNode;
  onChange?: Fn<PropertyValueRef<E>["value"], void>;
  variant?: PropertyTypeVariant;
  propTypeIcon?: boolean;
  inset?: boolean;
  className?: string;
  onClick?: Fn<React.MouseEvent, void>;
} & LabelProps;

const ReadControl = <E extends Entity>({
  valueRef,
  format,
  variant,
  propTypeIcon,
  onClick,
  className,
  ...rest
}: Props<E>) => {
  const icon = propTypeIcon ? (
    <PropertyTypeIcon
      field={valueRef.field as string}
      type={valueRef.type}
      options={valueRef.def?.options}
    />
  ) : undefined;
  const { type, value } = valueRef;
  const goTo = useGoTo();

  // Location field is just stored as a text field but has a special picker
  if (valueRef.field === "location") {
    return (
      <LocationLabel
        {...rest}
        variant={variant === "icon-only" ? "compact" : "full"}
        location={formatLocation(valueRef as PropertyValueRef<Entity>, format)}
      />
    );
  }

  if (
    !isDefined(value[type]) ||
    ((isArray(value[type]) || isString(value[type])) && isEmpty(value[type]))
  ) {
    return (
      <Label
        fit="content"
        subtle
        {...rest}
        className={cx(styles.placeholder, className)}
        onClick={onClick}
        icon={icon}
      >
        {toPlaceholder(valueRef, variant === "labelled", false)}
      </Label>
    );
  }

  switch (type) {
    case "text":
    case "number":
    case "email":
    case "url":
    case "title":
    case "phone":
      return (
        <Label fit="content" subtle {...rest} onClick={onClick} icon={icon}>
          {toLabel(valueRef, format || valueRef?.def?.format) || "None"}
        </Label>
      );

    case "rich_text":
      return (
        <Label fit="content" subtle {...rest} onClick={onClick} icon={icon}>
          {value[type]?.text || value[type]?.markdown || "None"}
        </Label>
      );

    case "date":
      return (
        <Label fit="content" subtle {...rest} onClick={onClick} icon={icon}>
          {toLabel(valueRef, format || valueRef?.def?.format)}
        </Label>
      );

    case "status":
      return (
        <Label
          fit="content"
          subtle
          {...rest}
          icon={rest.icon ?? <StatusIcon status={value[type]} />}
          onClick={onClick}
        >
          {variant !== "icon-only" && value?.[type]?.name}
        </Label>
      );

    case "select":
      return (
        <Label fit="content" subtle {...rest} onClick={onClick} icon={icon}>
          <Tag color={value?.[type]?.color} size={rest.size}>
            {value?.[type]?.name}
          </Tag>
        </Label>
      );

    case "multi_select":
      return (
        <Label fit="content" subtle {...rest} onClick={onClick} icon={icon}>
          <Tags tags={value[type] || []} size={rest.size} />
        </Label>
      );

    case "relations":
    case "relation": {
      const v = ensureArray(value[type]);
      return (
        <Label fit="content" subtle {...rest} icon={icon} onClick={onClick}>
          <Container
            gap={6}
            padding="none"
            stack={variant === "unlabelled" ? "horizontal" : "vertical"}
          >
            {map(v, (ref) => (
              <SpaceBetween key={ref.id}>
                {variant === "icon-only" ? (
                  <Icon icon={<RelationIcon relation={ref} />} />
                ) : (
                  <RelationLabel {...rest} relation={ref} fit="container" />
                )}
                {variant === "labelled" && (
                  <Button
                    size="tiny"
                    icon={ArrowUpRight}
                    onClick={withHardHandle(() => goTo(ref))}
                  />
                )}
              </SpaceBetween>
            ))}
          </Container>
        </Label>
      );
    }

    case "person":
      return (
        when(justOne(value[type]), (person) => (
          <RelationLabel
            fit="content"
            subtle
            {...rest}
            onClick={onClick}
            relation={person}
          />
        )) || <></>
      );
    default:
      throw new Error(
        `Unsupported property type (${type}) for prop (${
          valueRef.field as Maybe<string>
        }).`
      );
  }
};

export function PropertyLabel<E extends Entity>({
  className,
  children,
  onClick,
  propTypeIcon = false,
  variant = "unlabelled",
  ...props
}: Props<E>) {
  const values = useLazyPropertyValues(props.source, props.valueRef);

  const valueRef = useMemo(
    () => inflateValue(props.valueRef, values),
    [props?.valueRef, values]
  );

  return (
    <div className={cx(styles.container, className)}>
      <ReadControl
        {...props}
        propTypeIcon={propTypeIcon}
        variant={variant}
        valueRef={valueRef}
        onClick={onClick}
      />
    </div>
  );
}

export const InlinePropertyLabel = <E extends Entity>({
  entity,
  prop,
  propTypeIcon,
  ...props
}: Omit<Props<E>, "valueRef" | "source"> & {
  entity: E;
  prop: PropertyRef<E>;
}) => {
  const valueRef = useMemo(
    () => toPropertyValueRef(entity, prop),
    [entity, prop]
  );
  return (
    <PropertyLabel
      {...props}
      source={entity.source}
      valueRef={valueRef}
      variant="unlabelled"
      propTypeIcon={propTypeIcon ?? true}
    />
  );
};
