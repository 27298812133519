import { groupBy, isEmpty, map, some } from "lodash";
import { useEffect, useState, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";

import {
  EntityType,
  ID,
  Ref,
  RelationRef,
  FetchOptions,
  Entity,
  PropertyDef,
  FilterQuery,
} from "@api";
import { EntityForType, EntityMap } from "@api/mappings";

import { setItems } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";
import { set as loSet, reduce } from "lodash";

import {
  isPropForEntity,
  PropertyDefStoreAtom,
  useLazyProperties,
  useProperties,
} from "@state/databases";

import { useAsyncEffect } from "@utils/effects";
import { Maybe } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { ensureMany, OneOrMany } from "@utils/array";
import { maybeValues } from "@utils/object";
import { isLocalID, isTemplateId, maybeTypeFromId } from "@utils/id";
import { maybeMap, when } from "@utils/maybe";
import { hashable } from "@utils/serializable";
import { fromPointDate } from "@utils/date-fp";
import { orderItems } from "@utils/ordering";
import { useArrayKey } from "@utils/react";
import { Fn } from "@utils/fn";

import {
  getEntitiesLoader,
  getEntityLoader,
  getOptimizedForFilter,
  getItemsNestedWithin,
} from "../queries";

import { getStore } from "../atoms";
import { GenericItem, itemsForQuery } from "../selectors";

import { isInflated, toNestedTypes } from "../utils";
import { useGetItemFromAnyStore } from "./core";

// Sets items in their respective stores
const setItemsInStore = (items: OneOrMany<Entity>) =>
  map(
    groupBy(
      ensureMany(items),
      (l) => l?.source?.type || maybeTypeFromId(l.id) || ""
    ),
    (v, k) => !!k && setRecoil(getStore(k as EntityType), setItems(v))
  );

export const useLazyEntity = <T extends EntityType = EntityType>(
  id: Maybe<ID>,
  fetch: boolean = true
): Maybe<EntityForType<T>> => {
  const [entity, setEntity] = useRecoilState(GenericItem(id || ""));
  const fetching = useRef(false);

  // This async effect is being triggered repetitively when called inside the select components
  // Hack in place to stop infinite loading
  useAsyncEffect(async () => {
    if (
      !id ||
      (!!entity && !fetch) ||
      fetching.current ||
      maybeTypeFromId(id) === "workspace" ||
      isTemplateId(id) ||
      isLocalID(id)
    ) {
      return;
    }

    fetching.current = true;

    try {
      await getEntityLoader(
        id,
        when(entity?.updatedAt, fromPointDate),
        setItemsInStore
      );
    } finally {
      fetching.current = false;
    }
  }, [id, !entity]); // Reload when ID changes or if entity gets removed from store to trigger reload

  return entity as Maybe<EntityForType<T>>;
};

export const useEntityState = <T extends EntityType = EntityType>(): [
  Maybe<Entity | Ref>,
  Fn<Maybe<Ref>, void>
] => {
  const [ref, setRef] = useState<Ref>();
  const entity = useLazyEntity<T>(ref?.id);
  return [entity || ref, setRef];
};

export const useLazyEntities = <T extends EntityType = EntityType>(
  refs: Maybe<Ref[]>,
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const getItem = useGetItemFromAnyStore();
  const refsDep = useArrayKey(refs, (r) => r.id);

  useAsyncEffect(async () => {
    if (!fetch || isEmpty(refs) || some(refs, (r) => isLocalID(r.id))) {
      return;
    }

    await getEntitiesLoader(
      map(refs, (r) => r.id),
      setItemsInStore
    );
  }, [refsDep]);

  return useMemo(
    () => maybeMap(refs, (r) => getItem(r.id)),
    [getItem, refsDep]
  ) as EntityForType<T>[];
};

export const useOrderedEntities = <T extends EntityType = EntityType>(
  refs: Ref[],
  fetch: boolean = true
): Maybe<EntityForType<T>[]> => {
  const items = useLazyEntities<T>(refs, fetch);
  return useMemo(() => orderItems<EntityForType<T>>(items || []), [items]);
};

export const useLazyQuery = <T extends EntityType = EntityType>(
  type: T,
  filter: FilterQuery<EntityForType<T>>,
  opts?: FetchOptions
): EntityForType<T>[] => {
  const workspaceId = useActiveWorkspaceId();
  const hashed = useMemo(
    () => hashable({ type, filter, opts }),
    [type, filter, opts?.templates, opts?.fetch, opts?.archived]
  );
  const localItems = useRecoilValue(itemsForQuery(hashed));

  useAsyncEffect(async () => {
    if (opts?.fetch !== false) {
      // TODO: Should this store IDs of the results and return them
      // Causes a race condition with infiite loading ....
      const { changed } = await getOptimizedForFilter(
        { type, scope: workspaceId },
        filter,
        opts
      );
      setItemsInStore(changed);
    }
  }, [hashed.toJSON()]); // Cached by hashable

  return localItems as EntityForType<T>[];
};

export const useLazyRelation = (relation: Maybe<RelationRef>) => {
  return (
    useLazyEntity(isInflated(relation) ? undefined : relation?.id, false) ||
    relation
  );
};

const getAllNestedEntities = async (
  entity: Entity,
  allProps: PropertyDef[],
  onItems: (items: Entity[]) => void
): Promise<EntityMap> => {
  const childTypes = toNestedTypes(allProps);
  const result: EntityMap = {};

  await mapAll(childTypes, async <T extends EntityType>(type: T) => {
    const items = await getItemsNestedWithin(entity.id, {
      type: type as EntityType,
      scope: entity.source.scope,
    });
    // Only add if not empty
    if (items.length) {
      // @ts-ignore –– cant get lining up
      result[type] = items;
      onItems?.(items);
    }
  });

  return result;
};

export const useNestedEntities = (entity: Maybe<Entity>) => {
  const [children, setChildren] = useState<EntityMap>();
  const loading = useMemo(
    () => !!entity?.id && !children,
    [entity?.id, children]
  );
  const { props, ready } = useProperties(entity?.source);

  useEffect(() => {
    if (!entity) {
      return;
    }

    setChildren(undefined);
  }, [entity?.id]);

  useAsyncEffect(async () => {
    if (!entity || !ready) {
      return;
    }
    const items = await getAllNestedEntities(entity, props, setItemsInStore);
    setChildren(items);
  }, [entity?.id, useArrayKey(props, (p) => p.field), ready]);

  return useMemo(() => ({ children, loading }), [children, loading, ready]);
};

export const useNestedEntitiesOfType = <T extends EntityType>(
  entity: Maybe<Entity>,
  childType: T
) => {
  const [children, setChildren] = useState<EntityForType<T>[]>();
  const [loading, setLoading] = useState(false);

  useAsyncEffect(async () => {
    if (!entity) {
      return;
    }

    setLoading(true);

    const items = await getItemsNestedWithin(entity.id, {
      type: childType,
      scope: entity.source.scope,
    });
    setItemsInStore(items);
    setChildren(items as EntityForType<T>[]);

    setLoading(false);
  }, [entity?.id]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};

export const useManyNestedEntities = (parents: Maybe<Entity[]>) => {
  const [children, setChildren] = useState<EntityMap>();
  const [loading, setLoading] = useState(false);
  const props = useLazyProperties(parents?.[0]?.source);
  const propsStore = useRecoilValue(PropertyDefStoreAtom);

  useAsyncEffect(async () => {
    // Props not really loaded yet
    if (props.length <= 1) {
      return;
    }

    setLoading(true);

    // Fetch nested children for every entity
    const nestedMaps = await mapAll(parents || [], async (parent) => {
      // SInce we just needd the props to find the child types, look at all scoped props for this entity.
      const props = maybeValues(propsStore.lookup, (p) =>
        isPropForEntity(p, parent.source.type)
      );
      return getAllNestedEntities(parent, props, setItemsInStore);
    });

    // Merge them all into one massive entity map
    const combinedMap = reduce(
      nestedMaps,
      (comb, m) => {
        return reduce(
          m,
          (c, v, k) =>
            loSet(c, k, [...(c[k as EntityType] || []), ...(v as Entity[])]),
          comb
        );
      },
      {} as EntityMap
    );

    setChildren(combinedMap);
    setLoading(false);
  }, [useArrayKey(parents, (p) => p.id), useArrayKey(props, (p) => p.field)]);

  return useMemo(() => ({ children, loading }), [children, loading]);
};
