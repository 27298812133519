import { HasTemplate } from "@api";

import { useCreateFromTemplate } from "@state/generic";

import { useGoTo, usePushTo } from "@utils/navigation";

import { ViewEngine } from "@ui/engine";
import { HStack, SpaceBetween } from "@ui/flex";
import { Button } from "@ui/button";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { SelectableListItem } from "@ui/selectable-items";
import { RelationLabel } from "@ui/relation-label";
import { withHardHandle } from "@utils/event";
import { useCallback } from "react";

export const TemplateEngine: ViewEngine<HasTemplate> = {
  asMenuItem: () => <>Not supported.</>,
  asListCard: () => <>Not supported.</>,
  asListItem: function TemplateListItem({
    item,
    selection,
    setSelection,
    ...props
  }) {
    const goTo = useGoTo();
    const pushTo = usePushTo();

    const { create, loading } = useCreateFromTemplate(item.source, goTo, {
      appendName: false,
    });

    const handleUse = useCallback(() => {
      if (item?.source.type === "form") {
        pushTo(item);
      } else {
        create?.(item);
      }
    }, [create, item]);

    return (
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem
          item={item}
          selection={selection}
          setSelection={setSelection}
          {...props}
        >
          <SpaceBetween>
            <RelationLabel relation={item} />

            <HStack>
              {item.source.type === "form" && (
                <Button
                  loading={loading}
                  size="small"
                  onClick={() => pushTo([item, "/builder"])}
                >
                  Builder
                </Button>
              )}
              <Button
                variant="primary"
                loading={loading}
                size="small"
                onClick={withHardHandle(handleUse)}
              >
                Use
              </Button>
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },
};

export default TemplateEngine;
