import { ReactNode, useCallback, useState } from "react";

import { Ref } from "@api";

import { useCreateDocument } from "@state/resources";
import { useEntityLabel } from "@state/settings";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { Button } from "@ui/button";
import { Field, TextInput } from "@ui/input";
import { HStack, VStack } from "@ui/flex";
import { LocationSelect } from "@ui/select";
import { Dialog } from "@ui/dialog";

import styles from "./styles.module.css";
import { toLower } from "lodash";
import { showError } from "@ui/notifications";
import { FileAdd } from "@ui/icon";
import { useSource } from "@state/generic";

export interface Props {
  defaultName?: string;
  scope: string;
  onCreated?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const PageCreateDialog = ({
  defaultName,
  scope,
  onCancel,
  onCreated,
}: Props) => {
  const [title, setTitle] = useState(defaultName || "");
  const [saving, setSaving] = useState(false);
  const pageSource = useSource("page", scope);
  const createDocument = useCreateDocument(scope);
  const pageLabel = useEntityLabel("page", scope);
  const [location, setLocation] = useState<Maybe<string>>(() => scope);

  const onCreateDoc = useCallback(
    async (title?: string, location?: string) => {
      if (saving) {
        return;
      }

      setSaving(true);
      const doc = await createDocument(
        { title: title || `New ${pageLabel}` },
        location
      );
      if (!doc) {
        showError("Failed to create document.");
        setSaving(false);
        return;
      }

      onCreated?.(doc);
      setSaving(false);
    },
    [saving, createDocument]
  );

  return (
    <Dialog
      title={`New ${pageLabel}`}
      mode="blocking"
      onDismiss={onCancel}
      actions={
        <HStack>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() => onCreateDoc(title, location)}
          >
            Create {pageLabel}
          </Button>
        </HStack>
      }
    >
      <VStack gap={16} fit="container">
        <Field label="Title">
          <TextInput
            value={title}
            autoFocus={true}
            onChange={(text) => setTitle(text)}
            updateOn="blur"
            placeholder={`What should this ${toLower(pageLabel)} be called...`}
          />
        </Field>

        <Field label="Location">
          <LocationSelect
            fit="container"
            showTeam={true}
            location={location}
            className={{ trigger: styles.control }}
            onChange={(p) => setLocation(p)}
            source={pageSource}
          />
        </Field>
      </VStack>
    </Dialog>
  );
};

export const CreatePageButton = ({
  children,
  onCreated,
  ...props
}: { children?: ReactNode } & Omit<Props, "onCancel">) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open && (
        <PageCreateDialog
          {...props}
          onCancel={() => setOpen(false)}
          onCreated={(link) => {
            setOpen(false);
            onCreated?.({ id: link.id });
          }}
        />
      )}

      <Button
        size="small"
        subtle
        icon={FileAdd}
        onClick={() => setOpen((i) => !i)}
      >
        {children || "Create Page"}
      </Button>
    </>
  );
};
