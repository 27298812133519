import { atomFamily, selectorFamily } from "recoil";

import { ID, Form, PropertyType, PropertyValue } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";
import { SafeRecord } from "@utils/types";

export type FormData = SafeRecord<string, PropertyValue[PropertyType]>;

export type FormStoreState = StoreState<Form>;

export const WorkspaceFormStoreAtom = atomFamily<FormStoreState, ID>({
  key: "WorkspaceFormStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "form")],
});

export const FormStoreAtom = WorkspaceWrappedAtom(
  "FormStoreAtom",
  WorkspaceFormStoreAtom
);

export const FormAtom = selectorFamily({
  key: "FormAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(FormStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(FormStoreAtom, mergeItem<Form>(newValue));
      }
    },
});
