import { debounce } from "lodash";

import { getEnvVar } from "@utils/env";
import { Maybe, SafeRecord } from "@utils/maybe";

// Abstracts localStorage api

let _storage: Maybe<Storage> = undefined;
const _debounceSetters: SafeRecord<string, ReturnType<typeof debounce>> = {};

const initialize = (storage: Storage) => {
  const lastVersion = storage.getItem("traction.version");
  const currentVersion = getEnvVar("VERSION");

  // Compare major versions to see if local storage needs to be flushed
  if (lastVersion === currentVersion) {
    return storage;
  }

  // Completely clear store (including sessions if major version changes)
  if (lastVersion?.split(".")[0] !== currentVersion.split(".")[0]) {
    storage.clear();
  }
  // Clear all entity data if minor version changes
  else if (lastVersion?.split(".")[1] !== currentVersion.split(".")[1]) {
    for (var key in storage) {
      if (key?.includes("traction.store")) {
        storage.removeItem(key);
      }
    }
  }

  storage.setItem("traction.version", currentVersion);

  return storage;
};

export const storage = (): Storage => {
  if (!_storage) {
    _storage = initialize(localStorage);
  }

  return _storage;
};

export const getItem = (key: string): Maybe<string> => {
  return storage().getItem(key) || undefined;
};

export const setItemImmediate = (key: string, value: string) =>
  storage().setItem(key, value);

export const setItem = (key: string, value: string) => {
  if (!_debounceSetters[key]) {
    _debounceSetters[key] = debounce(setItemImmediate, 200, {
      leading: false,
      trailing: true,
    });
  }

  return _debounceSetters[key]?.(key, value);
};

// No debounce on remove item
export const removeItem = (key: string) => {
  storage().removeItem(key);
};
