import { filter, find, map, orderBy } from "lodash";
import { useCallback, useMemo, useRef } from "react";
import { useSetRecoilState } from "recoil";

import {
  claimJob,
  Entity,
  Job,
  JobStatus,
  Update,
  updateJobStatus,
} from "@api";

import { useQueueUpdates } from "@state/generic";
import {
  isLockedByMe,
  isTimedOut,
  isRunning,
  JobStoreAtom,
  RUNNERS,
  setJob,
  useJobQueue,
} from "@state/jobs";
import { useCurrentUser } from "@state/workspace";

import { useAsyncEffect } from "@utils/effects";
import { addInfo, log, debug } from "@utils/debug";
import { use } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { useWindowEvent } from "@utils/event";
import { when } from "@utils/maybe";
import { ensureMany, OneOrMany } from "@utils/array";
import { newID } from "@utils/id";

import { useCurrentPage } from "@ui/app-page";
import { WithMutateContext } from "@ui/mutate";
import { useOnce } from "@utils/hooks";
import { wait } from "@utils/promise";

// Lock key prevents multiple tabs from running the same job
const LOCK_KEY = newID();

const replaceCreatedBy = (
  updates: OneOrMany<Update<Entity>>,
  id: string
): Update<Entity>[] =>
  map(ensureMany(updates), (u) =>
    u.method === "create"
      ? {
          ...u,
          changes: [
            ...u.changes,
            {
              field: "createdBy",
              type: "relation",
              value: { relation: { id } },
            },
          ],
        }
      : u
  );

export const JobsQueue = () => {
  const me = useCurrentUser();
  const jobs = useJobQueue();
  const _mutate = useQueueUpdates();

  const myQueue = useMemo(
    () =>
      orderBy(
        // Unclaimed jobs or running jobs claimed by me
        filter(
          jobs,
          (j) =>
            j.status === "queued" ||
            (j.status === "running" && j.lockedBy?.id === me.id)
        ),
        (j) =>
          use(
            switchEnum(j.status, {
              running: 1,
              queued: 2,
              else: 3,
            }),
            (index) => `${index}-${j.createdAt}`
          ),
        "asc"
      ),
    [jobs]
  );
  const next = useMemo(() => myQueue?.[0], [myQueue]);

  const mutate = useCallback(
    (updates: OneOrMany<Update<Entity>>) => {
      if (next?.createdBy?.id) {
        _mutate(replaceCreatedBy(updates, next.createdBy.id));
      } else {
        _mutate(updates);
      }
    },
    [next?.createdBy?.id, _mutate]
  );

  return (
    <div style={{ display: "none" }}>
      <WithMutateContext mutate={mutate}>
        {next && <JobRunner key={next.id} job={next} />}
      </WithMutateContext>
    </div>
  );
};

interface RunnerProps {
  job: Job;
}

function JobRunner({ job }: RunnerProps) {
  const pageId = useCurrentPage();
  const me = useCurrentUser();
  const Runner = useMemo(
    () => find(RUNNERS, (r) => r.accepts(job))?.runner,
    [job]
  );
  const mutate = useQueueUpdates(pageId);
  const setStore = useSetRecoilState(JobStoreAtom);
  const claiming = useRef(false);
  const [once] = useOnce(`job-runner-${job.id}-${job.lockedAt}-${job.lockKey}`);

  const onCompleted = useCallback(
    async (job: Job) => {
      try {
        when(await updateJobStatus(job.id, JobStatus.Completed), (j) =>
          setStore(setJob(j))
        );
      } catch (err) {
        addInfo({ state: "Trying to complete running job.", job });
        log(err);
      }
    },
    [setStore]
  );

  const onFailed = useCallback(
    async (job: Job) => {
      try {
        when(await updateJobStatus(job.id, JobStatus.Failed), (j) =>
          setStore(setJob(j))
        );
      } catch (err) {
        addInfo({ state: "Trying to complete running job.", job });
        log(err);
      }
    },
    [setStore]
  );

  // Claim job when ready
  const handleClaimJob = useCallback(async () => {
    if (job.status === "queued" && !!Runner && !claiming.current) {
      claiming.current = true;
      try {
        // So that we don't claim jobs as soon as the window refocuses after being inactive for a long time...
        await wait("2 seconds");
        const updated = await claimJob(job.id, LOCK_KEY);
        when(updated, (u) => setStore(setJob(u)));
      } catch (err) {
        // Job already claimed by someone else, ignore the error
      } finally {
        claiming.current = false;
      }
    }
  }, [job.id, job.status]);

  useWindowEvent(
    "beforeunload",
    (e) => {
      if (
        isRunning(job) &&
        isLockedByMe(job, me, LOCK_KEY) &&
        !isTimedOut(job)
      ) {
        e.stopImmediatePropagation();
        e.returnValue =
          "Background jobs are being run. Leaving now could cause faulty data. Wait a few seconds and try again. Or reload anyway?";
      }
    },
    false,
    [job.status]
  );

  // Try claim job whenever ID, staus or Runner changes
  useAsyncEffect(handleClaimJob, [job.id, job.status, Runner]);

  // Not ready to run
  if (!isRunning(job)) {
    debug("Not ready to run job.");
    return <></>;
  }

  // No runner found
  if (!Runner) {
    debug("No runner found for job.");
    return <></>;
  }

  // Not locked by me, or is locked by another tab/window/component
  if (!isLockedByMe(job, me, LOCK_KEY)) {
    debug("Not locked by me.", { job, me, LOCK_KEY, Runner });
    return <></>;
  }

  if (isTimedOut(job)) {
    debug("Job timed out.", { job, me, LOCK_KEY, Runner });
    return <></>;
  }

  return (
    <Runner
      key={job.id}
      job={job}
      once={once}
      mutate={mutate}
      onCompleted={() => onCompleted(job)}
      onFailed={() => onFailed(job)}
    />
  );
}
