import { useMemo } from "react";
import {
  Action,
  Event,
  Agenda,
  Entity,
  EntityType,
  HasBlocked,
  Meeting,
  Page,
  Person,
  RelationRef,
  Schedule,
  Team,
  hasIcon,
  hasStatus,
  isResource,
  isView,
  toTitleOrName,
} from "@api";

import { useLazyRelation } from "@state/generic";
import { useLazyPropertyValue } from "@state/databases";
import { toSentence } from "@state/schedule";
import { isProcessId } from "@state/process";

import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { cx } from "@utils/class-names";
import { isLocalID, isPersonId, isTeamId, maybeTypeFromId } from "@utils/id";
import { usePushTo } from "@utils/navigation";
import { ComponentOrNode } from "@utils/react";

import {
  EmojiIcon,
  Icon,
  LinkIcon,
  PersonIcon,
  PlusIcon,
  StatusLive,
  TeamIcon,
  ViewIcon,
  FileAlt,
  Process,
  ArrowUpRight,
} from "@ui/icon";
import { Label, Props as LabelProps } from "@ui/label";
import { StatusIcon } from "@ui/status-button";
import { Tag } from "@ui/tag";
import { SpaceBetween } from "@ui/flex";
import { LocationLabel } from "@ui/location-button";
import { Ellipsis } from "@ui/ellipsis";
import { render, useEngine } from "@ui/engine";
import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { Tooltip, Props as TooltipProps } from "@ui/tooltip";
import { Button, Props as ButtonProps } from "@ui/button";
import { ActionCheckMark } from "@ui/engine/action";

import styles from "./relation-label.module.css";

type Props = {
  relation: Maybe<RelationRef>;
  icon?: boolean | ComponentOrNode;
} & Omit<LabelProps, "icon">;

type MenuProps = {
  relation: Maybe<RelationRef>;
  icon?: boolean | ComponentOrNode;
  onOpen?: Fn<Maybe<RelationRef>, void>;
} & Omit<MenuItemProps, "icon" | "onClick">;

const isPerson = (p: Maybe<RelationRef | Entity>): p is Person =>
  (p as Maybe<Person>)?.source?.type === "person";
const isAction = (p: Maybe<RelationRef | Entity>): p is Action =>
  (p as Maybe<Action>)?.source?.type === "action";
const isEvent = (p: Maybe<RelationRef | Entity>): p is Event =>
  (p as Maybe<Event>)?.source?.type === "event";
const isAgenda = (p: Maybe<RelationRef | Entity>): p is Agenda =>
  (p as Maybe<Agenda>)?.source?.type === "agenda";
const isTeam = (p: Maybe<RelationRef | Entity>): p is Team =>
  (p as Maybe<Team>)?.source?.type === "team";
const isMeeting = (p: Maybe<RelationRef | Entity>): p is Meeting =>
  (p as Maybe<Meeting>)?.source?.type === "meeting";
const isPage = (p: Maybe<RelationRef | Entity>): p is Page =>
  (p as Maybe<Page>)?.source?.type === "page";

export const StatusRelationIcon = ({ relation, size, className }: Props) => {
  const {
    value: { status },
  } = useLazyPropertyValue(relation as Entity, {
    field: "status",
    type: "status",
  });

  return (
    <StatusIcon
      className={className}
      size={size}
      blocked={(relation as Maybe<HasBlocked>)?.blocked}
      status={{
        ...status,
        blocked: (relation as Maybe<{ blocked: boolean }>)?.blocked,
      }}
    />
  );
};

export const RelationIcon = ({ relation, size, className }: Props) => {
  const inflated = useLazyRelation(relation);

  if (isView(inflated)) {
    return <ViewIcon layout={inflated?.layout} />;
  }

  if (isPerson(inflated)) {
    return <PersonIcon person={inflated} />;
  }

  if (isAction(inflated)) {
    return <ActionCheckMark status={inflated.status} />;
  }

  if (isEvent(inflated)) {
    return <EmojiIcon emoji="📅" />;
  }

  if (isAgenda(inflated)) {
    return <></>;
  }

  if (isTeam(inflated)) {
    return <TeamIcon team={inflated} />;
  }

  if (isMeeting(inflated)) {
    return inflated.status?.id === "INP" ? (
      <StatusLive />
    ) : (
      <EmojiIcon emoji="🤝" />
    );
  }

  if (hasIcon(inflated) && inflated.icon) {
    return <EmojiIcon emoji={inflated.icon} />;
  }

  if (when(relation?.id, isProcessId)) {
    return <Process />;
  }

  if (isResource(inflated)) {
    return inflated.url ? (
      <LinkIcon url={inflated.url} icon={inflated.icon} type={inflated.type} />
    ) : (
      <StatusLive />
    );
  }

  if (isPage(inflated)) {
    return <FileAlt />;
  }

  if (hasStatus(inflated)) {
    return (
      <StatusRelationIcon
        relation={inflated}
        size={size}
        className={className}
      />
    );
  }

  return <></>;
};

export const RelationMenuItem = ({ relation, icon, ...rest }: MenuProps) => {
  const inflated = useLazyRelation(relation) as Maybe<Entity>;
  const engine = useEngine(
    (inflated as Maybe<Entity>)?.source?.type ||
      when(relation?.id, maybeTypeFromId<EntityType>) ||
      "task"
  );

  if (!relation || !inflated) {
    return <></>;
  }

  if (!!isLocalID(relation.id)) {
    return (
      <MenuItem icon={PlusIcon}>Create "{toTitleOrName(inflated)}"</MenuItem>
    );
  }

  // if (isTemplate(inflated)) {
  //   return (
  //     <MenuItem icon={icon === true ? undefined : icon} {...rest}>
  //       <RelationLabel relation={relation} />
  //     </MenuItem>
  //   );
  // }

  return render(engine?.asMenuItem, {
    ...rest,
    key: relation?.id,
    item: inflated,
    icon: icon === true ? undefined : icon,
  });
};

export const RelationLabel = ({
  relation,
  subtle = false,
  icon = true,
  size,
  className,
  ...props
}: Props) => {
  const inflated = useLazyRelation(relation);

  if (inflated && (inflated as Schedule)?.source?.type === "schedule") {
    return (
      <Label
        className={className}
        subtle={subtle}
        {...props}
        size={size}
        icon={undefined}
        iconRight={undefined}
      >
        {toSentence(inflated as Schedule) || "Loading..."}
      </Label>
    );
  }

  return (
    <Label
      className={className}
      subtle={subtle}
      {...props}
      size={size}
      icon={
        icon ? (
          <Icon size={size} icon={<RelationIcon relation={inflated} />} />
        ) : undefined
      }
    >
      <Ellipsis>{toTitleOrName(inflated as Entity) || "Loading..."}</Ellipsis>
    </Label>
  );
};

export const RelationLocationLabel = ({ relation, ...rest }: Props) => {
  const inflated = useLazyRelation(relation);
  const showLocation = useMemo(
    () =>
      !!relation?.id && !isTeamId(relation?.id) && !isPersonId(relation?.id),
    [relation?.id]
  );

  return (
    <SpaceBetween gap={6}>
      <RelationLabel relation={inflated} fit="content" />

      {showLocation &&
        when((inflated as Maybe<Entity>)?.source?.scope, (scope) => (
          <LocationLabel size="small" location={scope} showTeam={true} />
        ))}
    </SpaceBetween>
  );
};

export const RelationTag = ({ relation, className, ...props }: Props) => (
  <Tag className={className}>
    <RelationLabel relation={relation} {...props} />
  </Tag>
);

export const InlineRelationLabel = ({
  relation,
  subtle = false,
  icon = true,
  size,
  className,
  ...props
}: Props) => {
  const inflated = useLazyRelation(relation);

  return (
    <Label
      className={cx(className, styles.inline)}
      subtle={subtle}
      {...props}
      size={size}
    >
      <Icon
        className={styles.inlineIcon}
        size={"small"}
        icon={<RelationIcon size="small" relation={inflated} />}
      />
      {toTitleOrName(inflated as Entity) || "Loading..."}
    </Label>
  );
};

export const RelationTooltip = ({
  relation,
  children,
  ...props
}: Props & Omit<TooltipProps, "text">) => {
  const inflated = useLazyRelation(relation);

  return (
    <Tooltip text={toTitleOrName(inflated as Entity)} {...props}>
      {children}
    </Tooltip>
  );
};

export const RelationButton = ({
  relation,
  size = "small",
  subtle = true,
  variant,
  ...props
}: Omit<Props, "size"> & Pick<ButtonProps, "size" | "subtle" | "variant">) => {
  const pushTo = usePushTo();

  if (!relation) {
    return <></>;
  }

  return (
    <Button
      size={size}
      subtle={subtle}
      variant={variant}
      onClick={() => pushTo(relation)}
      iconRight={ArrowUpRight}
    >
      <RelationLabel relation={relation} {...props} />
    </Button>
  );
};
